import axios from 'axios'
import { parse, stringify } from 'qs'

export const baseURL = import.meta.env.SSR ? 'http://localhost:8000/api/' : '/api/'

/**
 *
 * @param {string} cookies The value of the `Cookie` header
 */
export function startClient (cookies) {
  const options = {
    baseURL,
    paramsSerializer: {
      serialize: (params) => stringify(params, {
        encodeValuesOnly: true,
        arrayFormat: 'repeat'
      }),
      encode: parse
    }
  }
  if (cookies) {
    const crumbCookie = cookies.split('; ').find(cookie => cookie.startsWith('crumb='))?.split('=')?.[1]
    options.headers = { Cookie: cookies, 'X-CSRF-Token': crumbCookie }
  }
  const client = axios.create(options)

  client.interceptors.request.use(function (config) {
    if (!['get', 'head'].includes(config.method.toLowerCase())) {
      if (!import.meta.env.SSR) {
        const crumbCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('crumb=')).split('=')[1]
        config.headers['X-CSRF-Token'] = crumbCookie
      }
    }
    return config
  })
  return client
}
