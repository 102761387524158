<template>
  <div
    v-if="modelValue.length > 0 || showInvalidWarning"
    class="record-field"
  >
    <div :class="{ 'invalid-field': showInvalidWarning }">
      <h2
        class="h5--ssp"
        :class="{ 'invalid-field__label': showInvalidWarning }"
      >
        {{ $t(`record.${fieldName}`) }}:
      </h2>
      <div
        v-if="showInvalidWarning"
        class="invalid-field__warn"
      >
        <svg
          height="18"
          width="18"
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="var(--orange)"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              stroke-width="1.046"
              cx="9"
              cy="9"
              r="8.477"
            />
            <path
              d="M7.2 6.514c0-1.254.99-1.984 1.98-2.013.99-.03 1.98.66 1.98 1.894 0 1.999-2.148 1.122-2.2 3.625"
              stroke-width="1.046"
              stroke-linecap="round"
            />
            <path
              d="M9 13.185a.54.54 0 1 1 0-1.08.54.54 0 0 1 0 1.08Z"
              stroke-width=".25"
              fill="var(--orange)"
            />
          </g>
        </svg>
        <span>
          {{ $t('record.view.inconsistency.label') }}
        </span>
      </div>
    </div>
    <div
      v-if="!invalid"
      ref="togglePanelSection"
      :class="{ 'panel-is-expanded': true }"
      class="panel-expandable panel-will-expand"
    >
      <p class="authors-list">
        <span
          v-for="(user, index) in modelValue"
          :key="index"
        >
          <span>{{ user.name.trim() }}</span>
          <span v-if="user.user">
            (<RouterLink
              :to="{ name: 'ResearcherPage', params: { userId: user.userId } }"
              class="link"
            >{{ user.user.name.trim() }}</RouterLink>)</span>
          <span v-if="index < modelValue.length - 1">; </span>
        </span>
      </p>
    </div>
    <p v-else>
      {{ $t('record.view.inconsistency.value') }}
    </p>
  </div>
</template>

<script>
export default {
  inject: ['ignoredFields'],
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    fieldName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      authorsPanelIsExpanded: false
    }
  },
  computed: {
    invalid () {
      if (this.ignoredFields) {
        return this.ignoredFields.includes(this.fieldName)
      }
      return false
    },
    showInvalidWarning () {
      return this.invalid && this.$auth?.isLogged()
    }
  },
  methods: {
    togglePanel () {
      const togglePanelSection = this.$refs.togglePanelSection
      const togglePanelClosedHeight = '100px'
      // let togglePanelTabNavigation = this.$refs.togglePanelTabNavigation
      if (togglePanelSection.style.maxHeight === togglePanelClosedHeight) {
        // togglePanelSection.style.maxHeight = togglePanelSection.scrollHeight + 'px'
        // togglePanelSection.style.maxHeight = null
      } else {
        togglePanelSection.classList.toggle('panel-is-expanded')
        // togglePanelSection.style.maxHeight = togglePanelClosedHeight
        togglePanelSection.style.maxHeight = togglePanelSection.scrollHeight + 'px'
      }
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/variables" as *;
@use "@/assets/scss/record" as *;

.panel-will-expand {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  margin-bottom: 8px;
  transition-property: max-height;
  @include md-transition;
}

.panel-will-expand::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
}

.panel-will-expand.panel-is-expanded::after {
  content: initial;
}

.panel-will-expand.panel-is-expanded + .btn--arrow-down::after {
  transform: translateY(-30%) rotate(-45deg);
}
.authors-list,
.authors-list .link {
  word-break: normal;
}
</style>
