<template>
  <div>
    <ul class="card">
      <Record
        v-for="record in records"
        :key="record.id"
        tag="li"
        class="card-row"
        :record="record"
        :highlight="record?.metadata?.highlight"
        :editable="showRecordActions(record)"
        :removable="showRecordActions(record)"
        :compact="compact"
        :highlight-title="highlightTitles"
        :units="units"
        @delete="openDeleteConfirmationModal"
      />
    </ul>
    <DeleteRecordConfirmation
      v-if="canDeleteRecord"
      v-model="showDeleteConfirmationModal"
      :record="recordToDelete"
      @deleted="recordDeleted"
    />
  </div>
</template>

<script>
import useUnitsAPI from '@/api/units'

import Record from '@/components/search/results/Record.vue'
import DeleteRecordConfirmation from '@/components/records/DeleteRecordConfirmation.vue'
import { mapState } from 'vuex'
import { isUnitManagerOfAuthorMemberships, isRecordAuthor } from '@/utils/record-helpers'

export default {
  components: {
    Record,
    DeleteRecordConfirmation
  },
  inject: ['$axios'],
  props: {
    records: {
      type: Array,
      required: true
    },
    showActions: {
      default: undefined,
      type: Boolean
    },
    highlightTitles: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: true
    }
  },
  emits: ['deleted'],
  data () {
    return {
      UnitsAPI: useUnitsAPI(this.$axios),
      showDeleteConfirmationModal: false,
      recordToDelete: {},
      units: []
    }
  },
  computed: {
    ...mapState(['profile']),
    canDeleteRecord () {
      return this.$auth.isLogged() && !!this.recordToDelete.metadata && (
        this.profile.roles.includes('operator') ||
        isRecordAuthor({ record: this.recordToDelete, userId: this.profile.id }) ||
        isUnitManagerOfAuthorMemberships({ record: this.recordToDelete, profile: this.profile })
      )
    }
  },
  async created () {
    const unitIdsSet = new Set()
    this.records.forEach((record) => {
      ['creators', 'advisors', 'editors'].map(async field => {
        if (record[field] === undefined) {
          return
        }
        if (record.metadata.highlight?.[`${field}.affiliations`]) {
          record.metadata.highlight[`${field}.affiliations`].map(u => this.removeSearchTags(u)).map(id => unitIdsSet.add(id))
        }
      })
    })
    const unitIds = Array.from(unitIdsSet)
    this.units = await Promise.all(unitIds.map(unitID => this.UnitsAPI.get({ unitID })))
  },
  methods: {
    removeSearchTags (val) {
      return val.replaceAll('SEARCH-MATCH-START', '').replaceAll('SEARCH-MATCH-END', '')
    },
    async openDeleteConfirmationModal (id) {
      this.showDeleteConfirmationModal = true
      this.recordToDelete = this.records.find(r => r.metadata.id === id) ?? {}
    },
    recordDeleted (id) {
      this.$emit('deleted', id)
    },
    showRecordActions (record) {
      if (this.showActions !== undefined) {
        return this.showActions
      }
      return this.$auth.isLogged() && !!record.metadata && (
        this.profile.roles.includes('operator') ||
        isRecordAuthor({ record, userId: this.profile.id }) ||
        isUnitManagerOfAuthorMemberships({ record, profile: this.profile })
      )
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/variables" as *;
.list-records__actions-list {
  display: flex;
  flex-flow: row nowrap;
}

// .list-records__actions-list__item {
//   margin-right: 1rem;
//   &:last-child {
//     margin-right: 0;
//   }
// }
</style>
