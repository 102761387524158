<template>
  <div
    v-if="usersInSystem.length"
    class="record-field"
  >
    <h2 class="h5--ssp">
      {{ sectionTitle }}:
    </h2>
    <ul class="users-list">
      <li
        v-for="user in usersInSystem"
        :key="user.id"
        class="users-list__item"
      >
        <div class="users-list__data">
          <div class="figure figure--avatar figure--40">
            <UserAvatar
              :src="user.avatar"
              :name="user.name"
            />
          </div>
          <div class="users-list__text">
            <h2 class="users-list__item-name h5--ssp">
              <RouterLink :to="{ name: 'ResearcherPage', params: { userId: user.username } }">
                {{ user.name }}
              </RouterLink>
            </h2>
            <p class="u-text-gray">
              {{ user.username }}
            </p>
          </div>
        </div>
        <collapsible-list
          v-if="hasAffiliationsAndSources(user)"
          class="users-list__affiliations"
        >
          <template #trigger-text>
            {{ $t('institution.users.part.collapsible.trigger') }}
          </template>
          <template #list>
            <div
              v-for="affiliationOrSource in affiliationsAndSources(user)"
              :key="affiliationOrSource.id"
              class="users-list__affiliation-item"
            >
              <div v-if="affiliationOrSource.acronym">
                <p class="small">
                  <unit-breadcrumb :unit="affiliationOrSource" />
                </p>
                <p class="users-list__affiliation-item-name">
                  <RouterLink
                    :to="{ name: 'UnitPage', params: { unitID: affiliationOrSource.id } }"
                    class="card-row__link"
                  >
                    {{ affiliationOrSource.name }}
                  </RouterLink>
                </p>
              </div>
              <div v-else>
                <ExternalRepositoriesBadge
                  :external-sources="[affiliationOrSource.source]"
                />
                <p
                  v-if="affiliationOrSource.url"
                  class="users-list__affiliation-item-name"
                >
                  <a
                    class="u-active-link"
                    rel="noreferrer"
                    :href="affiliationOrSource.url"
                  >
                    ID: {{ affiliationOrSource.id }}</a>
                </p>
                <p
                  v-else
                  class="users-list__affiliation-item-name"
                >
                  ID: {{ affiliationOrSource.id }}
                </p>
              </div>
            </div>
          </template>
        </collapsible-list>
      </li>
    </ul>
  </div>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import CollapsibleList from '@/components/utils/CollapsibleList.vue'
import UnitBreadcrumb from '@/components/units/UnitBreadcrumb.vue'
import ExternalRepositoriesBadge from '@/components/records/ExternalRepositoriesBadge'

export default {
  components: {
    UserAvatar,
    CollapsibleList,
    UnitBreadcrumb,
    ExternalRepositoriesBadge
  },
  props: {
    sectionTitle: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      usersInSystem: this.users.filter(user => user.user).map(user => ({ ...user.user, affiliations: user.affiliations ?? [], externalSources: user.externalSources }))
    }
  },
  methods: {
    affiliationsAndSources (user) {
      const affiliations = user.affiliations ?? []
      const externalSources = user.externalSources ?? []
      return [...affiliations, ...externalSources]
    },
    hasAffiliationsAndSources (user) {
      return this.affiliationsAndSources(user).length > 0
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/variables" as *;

.users-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  align-items: start;
  margin-bottom: -16px;
}

.users-list__item {
  display: flex;
  flex: auto;
  flex-flow: column nowrap;
  max-width: 17rem;
  margin-bottom: 32px;
}

.users-list__text {
  flex-direction: column;
  margin-left: 12px;
}
.users-list__data {
  display: flex;
  flex: auto;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.users-list__affiliations {
  margin-left: 52px;
}

.users-list__affiliation-item {
  margin: 6px 0;
}
.users-list__affiliation-item-name {
  font-weight: 600;
}

.users-list__item-name {
  margin: 0;
}
</style>
