<template>
  <Transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="!alwaysShowSearch && isAlwaysEmpty"
      key="always-empty"
    >
      <slot
        name="empty-state"
        :is-always-empty="true"
      />
    </div>
    <div
      v-else
      key="search-body"
      class="search-paginated-list__body"
    >
      <slot
        name="search-bar"
        :button-attrs="isInitialLoading ? { class: 'f-field-search__submit--skeleton', disabled: true } : {}"
        :input-attrs="isInitialLoading ? { class: 'f-field-search__input--skeleton', disabled: true } : {}"
      />
      <slot
        name="list-header"
        :is-loading="isInitialLoading || (isSearching && emptySearch)"
        :is-searching="isSearching"
      >
        <div class="tab-navigation">
          <MultiStateCounter
            :is-loading="isInitialLoading || (isSearching && emptySearch)"
            :is-searching="isSearching"
            :count="pageData ? pageData.totalItems : 0"
            class="tab-navigation__counter"
          />
        </div>
      </slot>
      <Transition
        name="fade"
        mode="out-in"
      >
        <section
          v-if="isInitialLoading || isSearching || (pageData && pageData.totalItems > 0)"
          key="ok-or-searching"
        >
          <Transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="isSearching || isInitialLoading"
              key="searching-skeleton"
            >
              <slot
                name="list-skeleton"
                :is-initial-loading="isInitialLoading"
              />
            </div>
            <div
              v-else
              key="search-results"
            >
              <slot name="list" />
            </div>
          </Transition>
          <Transition name="fade">
            <slot
              v-if="pageData"
              name="pagination"
            />
          </Transition>
        </section>
        <div
          v-else
          key="search-results-empty"
        >
          <slot
            name="empty-state"
            :is-always-empty="alwaysShowSearch ? isAlwaysEmpty : false"
          />
        </div>
      </Transition>
    </div>
  </Transition>
</template>
<script>
import MultiStateCounter from '@/components/utils/MultiStateCounter.vue'

export default {
  components: {
    MultiStateCounter
  },
  props: {
    isInitialLoading: {
      type: Boolean,
      required: true
    },
    isSearching: {
      type: Boolean,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    pageData: {
      type: Object,
      default: undefined
    },
    alwaysShowSearch: {
      type: Boolean,
      default: false
    },
    emptyCheck: {
      type: Function,
      default: function () { return this.query === '' }
    }
  },
  computed: {
    isAlwaysEmpty () {
      const hasQueryParams = Object.keys(this.$route.query).length > 0
      return !this.isSearching && !hasQueryParams && this.pageData?.totalItems === 0
    },
    emptySearch () {
      return this.emptyCheck()
    }
  }
}
</script>
<style lang="scss">
@use "@/assets/scss/variables" as *;

.search-paginated-list__body {
  .f-field-search__submit,
  .f-field-search__input::placeholder {
    @include md-transition;
  }
  .f-field-search__submit--skeleton {
    opacity: 0.2;
  }
  .f-field-search__input--skeleton::placeholder {
    color: transparent;
    box-shadow: 0 -2px 0 $light-gray-400 inset;
  }
}
</style>
