import * as types from './mutation-types'
import useProfileAPI from '@/api/profile'
import useUnitsAPI from '@/api/units'

/**
 *
 * @param axios
 */
export default function (axios) {
  const ProfileAPI = useProfileAPI(axios)
  const UnitsAPI = useUnitsAPI(axios)

  return {
    setTopMessage: async ({ commit }, { active, msg, dismiss, type }) => {
      commit(types.SET_TOP_MESSAGE, { active, msg, dismiss, type })
    },
    fetchProfile: async ({ commit }) => {
      const data = await ProfileAPI.get()
      commit(types.RECEIVED_PROFILE, { profile: data })
    },
    fetchManagedUnits: async ({ commit, state }) => {
      const unitIds = state.profile.units
      if (!unitIds || unitIds.length === 0) {
        commit(types.RECEIVED_MANAGED_UNITS, { managedUnits: [] })
        return
      }
      const promises = []
      for (const id of unitIds) {
        promises.push(UnitsAPI.get({ unitID: id }))
      }
      const units = await Promise.all(promises)
      commit(types.RECEIVED_MANAGED_UNITS, { managedUnits: units })
    },
    fetchExternalProfiles: async ({ commit }) => {
      const data = await ProfileAPI.getExternalProfiles()
      commit(types.RECEIVED_EXTERNAL_PROFILES, data)
    },
    setConfigurationState: ({ commit }, { configuration }) => {
      commit(types.SET_CONFIGURATION_STATE, configuration)
    },
    setProgressBar: ({ commit }, progress) => {
      commit(types.SET_PROGRESS_BAR_STATE, progress)
    },
    completeProgressBar: ({ commit }) => {
      commit(types.SET_PROGRESS_BAR_STATE, 100)
    }
  }
}
