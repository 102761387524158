<template>
  <Modal
    v-model="isModalOpen"
    class="modal--lg"
  >
    <template #modal-panel>
      <slot
        name="header"
        :resource-name="resourceName"
      >
        <h1 class="modal__title">
          {{ $t('delete.confirmation.title', { name: resourceName }) }}
        </h1>
      </slot>
      <slot
        name="consequences-description"
        :resource-name="resourceName"
      />
      <p>{{ $t('delete.confirmation.action.part1') }}</p>
      <p><span class="u-text-literal label label--sm">{{ confirmationText }}</span></p>
      <p>{{ $t('delete.confirmation.action.part2') }}</p>
      <div class="f-field">
        <input
          id="delete-confirmation-modal-input"
          ref="confirmationInput"
          v-model="confirmationInput"
          name="delete-confirmation-modal-input"
          type="text"
          class="f-field__input"
        >
        <label
          for="delete-confirmation-modal-input"
          class="f-field__label"
        />
      </div>
      <div class="btn-group">
        <button
          class="btn btn--outline"
          @click="close"
        >
          {{ $t('actions.cancel') }}
        </button>
        <button
          :disabled="normalize(confirmationInput) !== normalize(confirmationText)"
          class="btn btn--danger"
          @click="deleteResource"
        >
          <slot name="delete-button-text" />
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/utils/Modal.vue'
import { normalize } from '@/utils/search-helpers'

export default {
  components: {
    Modal
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    confirmation: {
      type: String,
      default: ''
    },
    resourceName: {
      type: String,
      required: true
    }
  },
  emits: ['input', 'delete', 'update:modelValue'],
  data () {
    return {
      confirmationInput: ''
    }
  },
  computed: {
    confirmationText () {
      return this.confirmation === '' ? this.resourceName : this.confirmation
    },
    isModalOpen: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('update:modelValue', val)
      }
    }
  },
  watch: {
    isModalOpen (val) {
      if (val) {
        setTimeout(() => {
          this.$refs.confirmationInput?.focus()
        }, 200)
      } else {
        this.confirmationInput = ''
      }
    }
  },
  methods: {
    close () {
      this.confirmationInput = ''
      this.isModalOpen = false
    },
    deleteResource () {
      this.$emit('delete', true)
    },
    normalize
  }
}
</script>
