<template>
  <footer class="app-footer">
    <div class="container app-footer-container">
      <div class="app-footer__col">
        <ul class="app-footer__navigation">
          <li class="app-footer__list-item">
            <a
              href="https://fenixedu.org"
              rel="noreferrer"
              target="_blank"
            >
              Powered by FenixEdu&trade;
            </a>
          </li>
          <li
            v-for="(link, index) in configuration.footerLinks"
            :key="index"
            class="app-footer__list-item"
          >
            <a
              :href="link.url"
              target="_blank"
              rel="noreferrer"
            >
              {{ $t(link.translationKey) }}
            </a>
          </li>
        </ul>
        <p class="app-footer__copyright">
          {{ getCopyrightYear(2022) }} ©
          <a
            rel="noreferrer"
            target="_blank"
            :href="configuration.copyrightURL"
          >
            {{ $t('footer.copyright') }}
          </a>
        </p>
      </div>
      <div class="app-footer__col">
        <span class="h6 app-footer__logos-title">
          {{ $t('footer.cofinanced.title') }}
        </span>
        <ul class="app-footer__logos-list">
          <li class="app-footer__logo-item">
            <img
              width="151"
              height="63"
              src="@/assets/images/compete-2020-logo.webp"
              :alt="$t('footer.cofinanced.alt.attribution', { name: 'Compete 2020' })"
              loading="lazy"
            >
          </li>
          <li class="app-footer__logo-item">
            <img
              width="151"
              height="63"
              src="@/assets/images/portugal-2020-logo.webp"
              :alt="$t('footer.cofinanced.alt.attribution', { name: 'Portugal 2020' })"
              loading="lazy"
            >
          </li>
          <li class="app-footer__logo-item">
            <img
              width="151"
              height="63"
              src="@/assets/images/eu-social-fund-logo.webp"
              :alt="$t('footer.cofinanced.alt.attribution', { name: 'EU Social Fund' })"
              loading="lazy"
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({ configuration: state => state.configuration })
  },
  methods: {
    getCopyrightYear (startYear) {
      if (new Date().getFullYear() > startYear) {
        return `${startYear}-${new Date().getFullYear()}`
      } else {
        return startYear
      }
    }
  }
}
</script>

<style lang="scss">
@use "sass:color";
@use "@/assets/scss/variables" as *;

.app-footer {
  background-color: white;
  box-shadow: 0 -0.0625rem 0 0 color.change($dark, $alpha: 0.1);
  font-size: 0.875rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.app-footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (width <= 46.875rem) {
    flex-flow: column nowrap;
    align-items: center;
  }
}
.app-footer__col {
  display: flex;
  flex-flow: column nowrap;
  max-width: 50%;
}

.app-footer__copyright {
  margin-top: auto;
  font-size: 0.875rem;

  @media screen and (width <= 46.875rem) {
    margin-top: 1rem;
    text-align: center;
  }
}

.app-footer__list-item {
  font-size: 0.875rem;

  @media screen and (width <= 46.875rem) {
    padding: 0.25rem 0;
  }
}

.app-footer__navigation {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem 2rem;
  justify-content: flex-start;
  color: $dark;

  @media screen and (width <= 46.875rem) {
    flex-flow: column nowrap;
    text-align: center;
    justify-content: stretch;
  }

}
.app-footer__logos-list {
  display: flex;
  flex-flow: row nowrap;
  @media screen and (width <= 46.875rem) {
    flex-flow: column nowrap;
    margin-bottom: 2rem;
  }
}

.app-footer__logo-item {
  max-width: 9.4375rem;
  max-height: 3.9375rem;
  margin: 0 0.5rem;
  &:last-child,
  &:first-child {
    margin: 0;
  }
  img {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
  }
}
.app-footer__logos-title {
  margin-bottom: 0.5rem !important;
  font-size: 0.75rem;
  @media screen and (width <= 46.875rem) {
    text-align: center;
    padding-top: 1.75rem;
  }
}
</style>
