const decode = decodeURIComponent
const pairSplitRegExp = /; */

/**
 *
 * @param str
 * @param decode
 */
function tryDecode (str, decode) {
  try {
    return atob(str)
  } catch (e) {
    return str
  }
}

/**
 * Parses a string into key-value pairs using the specified options.
 * @param {string} str - The input string containing key-value pairs
 * @param {object} [options] - Additional parsing options, such as:
 *     - decode: Function to use for decoding values (default: decode)
 *     - pairSplitRegExp: Regular expression used to split the string into pairs (default: See Constants.PAIR_SPLIT_REGEXP)
 * @returns {object} The parsed key-value object
 *
 * This function handles quoted values by removing the surrounding quotes and attempts to decode the value with the provided decode function.
 * Each key is only set once in the resulting object, using the first occurrence.
 */
function parse (str, options) {
  if (typeof str !== 'string') throw new TypeError('argument str must be a string')
  if (!str) return {}

  const obj = {}
  const opt = options || {}
  const pairs = str.split(pairSplitRegExp)
  const dec = opt.decode || decode

  pairs.forEach(pair => {
    let [key, val] = pair.split('=')

    if (!key) return
    if (!val) return

    // quoted values
    if (val[0] === '"') {
      val = val.slice(1, -1)
    }

    // only assign once
    if (undefined === obj[key]) {
      obj[key] = tryDecode(val, dec)
    }
  })

  return obj
}

const getUserRolesFromCookie = () => {
  if (!import.meta.env.SSR) {
    if (!document?.cookie) return null
    const entries = parse(document.cookie)

    if (!entries.P_INFO) return []
    const info = JSON.parse(entries.P_INFO)
    return info?.roles ?? []
  } else {
    return []
  }
}

const SocketIOPromise = async () => (await import('socket.io-client')).io

export const startingWebSocket = startWebSocket()

/**
 * Starts an websocket connection with the server if the user is logged in
 * @returns A promise to a Socket connection or null if the user is not logged in
 */
async function startWebSocket () {
  // Only connect via websocket for logged users
  if (!import.meta.env.SSR && getUserRolesFromCookie()?.length > 0) {
    try {
      const io = await SocketIOPromise()
      if (window.location.host.includes('localhost')) {
        return io('http://localhost:8000/', { transports: ['websocket'] })
      } else {
        return io('/', { transports: ['websocket'] })
      }
    } catch (error) {
      console.error('Error connecting to WebSocket')
    }
  }
  return null
}
