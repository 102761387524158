import AccountSettings from '@/router/private/settings/account'
import TasksSettings from '@/router/private/settings/tasks'
import ManagementSettings from '@/router/private/settings/management'
import AdminSettings from '@/router/private/settings/admin'
const SettingsPage = async () => import('@/pages/SettingsPage.vue')

/**
 *
 * @param store
 * @param axios
 */
export default function (store, axios) {
  return [
    {
      path: '/settings',
      name: 'SettingsPage',
      redirect: { name: 'AccountPage' },
      component: SettingsPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch('fetchManagedUnits')
        } catch (err) {
          console.error(err)
          // Vue.prototype.$notification.send({ key: 'error.settings.menu.managed.units', type: 'warn', dismiss: true })
        } finally {
          next()
        }
      },
      children: [
        ...AccountSettings(store, axios),
        ...TasksSettings(axios),
        ...ManagementSettings(axios),
        ...AdminSettings(axios)
      ]
    }
  ]
}
