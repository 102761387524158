<template>
  <span
    v-if="externalSources && externalSources.length > 0"
    class="p--default small external-repositories-badge"
  >
    <svg
      aria-hidden="true"
      width="15"
      height="8"
      viewBox="0 0 15 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke="var(--gray-400)"
        stroke-width=".933"
        fill="none"
        fill-rule="evenodd"
      >
        <rect
          width="8.4"
          height="6.067"
          rx="3.033"
        />
        <rect
          x="4.667"
          width="8.4"
          height="6.067"
          rx="3.033"
        />
      </g>
    </svg>
    {{ $t('external.repositories.badge') }}: {{ repositories }}
  </span>
</template>

<script>

export default {
  props: {
    externalSources: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    repositories () {
      return this.externalSources.join(', ')
    }
  }
}
</script>
<style lang="scss">
@use "@/assets/scss/variables" as *;

.external-repositories-badge {
  margin-left: 0.2rem;
  margin-right: 0.1rem;
  text-transform: uppercase;
  color: $gray-400;
}
</style>
