import useAuthorsAPI from '@/api/researchers'
import useProfileAPI from '@/api/profile'
import useUnitsAPI from '@/api/units'
import useRecordsAPI from '@/api/records'
import useUsernamesAPI from '@/api/usernames'

import { fetchAllPages } from '@/utils/pagination-helpers'

const decode = decodeURIComponent
const pairSplitRegExp = /; */

/**
 *
 * @param str
 * @param decode
 */
function tryDecode (str, decode) {
  try {
    return atob(str)
  } catch (e) {
    return str
  }
}

/**
 * Parses a string into key-value pairs using the specified options.
 * @param {string} str - The input string containing key-value pairs
 * @param {object} [options] - Additional parsing options, such as:
 *     - decode: Function to use for decoding values (default: decode)
 *     - pairSplitRegExp: Regular expression used to split the string into pairs (default: See Constants.PAIR_SPLIT_REGEXP)
 * @returns {object} The parsed key-value object
 *
 * This function handles quoted values by removing the surrounding quotes and attempts to decode the value with the provided decode function.
 * Each key is only set once in the resulting object, using the first occurrence.
 */
function parse (str, options) {
  if (typeof str !== 'string') throw new TypeError('argument str must be a string')
  if (!str) return {}

  const obj = {}
  const opt = options || {}
  const pairs = str.split(pairSplitRegExp)
  const dec = opt.decode || decode

  pairs.forEach(pair => {
    let [key, val] = pair.split('=')

    if (!key) return
    if (!val) return

    // quoted values
    if (val[0] === '"') {
      val = val.slice(1, -1)
    }

    // only assign once
    if (undefined === obj[key]) {
      obj[key] = tryDecode(val, dec)
    }
  })

  return obj
}

export const getUserRolesFromCookie = () => {
  if (!import.meta.env.SSR) {
    if (!document?.cookie) return null
    const entries = parse(document.cookie)

    const info = JSON.parse(entries.P_INFO)
    return info?.roles ?? []
  } else {
    return []
  }
}

/**
 *
 * @param axios
 */
export default function (axios) {
  const RecordsAPI = useRecordsAPI(axios)
  const ProfileAPI = useProfileAPI(axios)
  const UnitsAPI = useUnitsAPI(axios)
  const AuthorsAPI = useAuthorsAPI(axios)
  const UsernamesAPI = useUsernamesAPI(axios)

  return {

    fetchProfileExternalRepositories: async () => {
      const { externalProfiles } = await ProfileAPI.getExternalProfiles()
      return externalProfiles
    },

    fetchAuthorByUserId: async ({ userId, countView }) => {
      // FIXME: should be fetchAuthorByUsername when we start identifying authors by username
      return AuthorsAPI.get({ userId, countView })
    },

    getUserIdFromUsername: async ({ username }) => {
      // FIXME: should be getUsernameForAuthorUserId when we start identifying authors by username
      const { userId } = await UsernamesAPI.getUserIdFromUsername({ username })
      return userId
    },

    fetchRootUnit: () => {
      return UnitsAPI.getRoot()
    },

    fetchAllSubUnits: ({ parentId }) => {
      return fetchAllPages(page => {
        return UnitsAPI.getSubUnits({ unitID: parentId, page, perPage: 20 })
      })
    },

    fetchUnitTypes: async () => {
      return UnitsAPI.listTypes()
    },

    fetchUnit: async ({ id, countView }) => {
      return UnitsAPI.get({ unitID: id, countView })
    },

    fetchRecord: async ({ id, allowDraft, sources, countView, mergeHistory }) => {
      return RecordsAPI.get({ id, allowDraft, sources, countView, mergeHistory })
    },

    fetchAllUnitManagers: async ({ id }) => {
      return fetchAllPages(page => {
        return UnitsAPI.getManagers({ unitID: id, page, perPage: 20 })
      })
    },

    isUnitManager: async ({ unitId }) => {
      const { units } = await ProfileAPI.get()
      return units.includes(unitId)
    }
  }
}

/**
 * @callback GetErrorRoute
 * @param {Error} error
 * @returns {Parameters<Parameters<import('vue-router').NavigationGuard>[2]>[0]}
 */
/**
 * Wraps a navigation guard. The returned guard will catch axios errors during the
 * execution of the wrapped `guard`. If the error has a 404 status, it redirects
 * to `PageNotFound`. If another error happens, calls `getErrorRoute` if it is
 * defined, otherwise aborts navigation altogether.
 *
 * **NOTE:** If any of the functions you pass as parameters need to access the
 * `this` keyword, make sure that they are **not** arrow functions, as this wrapper
 * will not be able to bind its `this` context to your functions.
 * @param {import('vue-router').NavigationGuard} guard A vue-router navigation guard
 * @param {GetErrorRoute} [getErrorRoute] A function that receives an Error and
 * returns the next route. If not defined, `next(false)` is called when there's
 * an error
 * @returns {import('vue-router').NavigationGuard} The wrapper navigation guard
 */
export const guardWithErrorHandling = (guard, getErrorRoute) => {
  // cannot be arrow function because of 'this' context
  return async function (to, from, next) {
    try {
      await guard.bind(this)(to, from, next)
    } catch (err) {
      if (err.response?.status === 404) {
        return next({ name: 'PageNotFound', params: [to.path], replace: false })
      } else if (getErrorRoute) {
        next(getErrorRoute.bind(this)(err))
      } else {
        next({ name: 'ErrorPage', params: { error: err } })
      }
    }
  }
}
