<template>
  <div>
    <article class="publication publication--preview">
      <slot name="header" />
      <div class="card">
        <div class="card-row">
          <div class="card-row__text">
            <h2
              class="h4"
              style="margin: 0;"
            >
              {{ t('sections.keyInformation') }}
            </h2>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row__text">
            <slot name="keyInformation" />
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-row">
          <div class="card-row__text">
            <h2
              class="h4"
              style="margin: 0;"
            >
              {{ t('sections.publicationDetails') }}
            </h2>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row__text">
            <slot name="publicationDetails" />
            <files-part
              class="record-field"
              :files="record.files"
              :date-available="record.dateAvailable"
              :rights="record.rights"
              layout="preview"
            />
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import FilesPart from '@/components/records/views/parts/FilesPart.vue'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    FilesPart
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          sections: {
            keyInformation: 'Informações chave',
            publicationDetails: 'Detalhes da publicação'
          }
        },
        en: {
          sections: {
            keyInformation: 'Key information',
            publicationDetails: 'Publication details'
          }
        }
      }
    })
    return { t }
  }
}
</script>
