<template>
  <div class="empty-state">
    <div class="empty-state__illustration">
      <svg
        width="117"
        height="76"
        viewBox="0 0 117 76"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="var(--gray)"
          stroke-width="2"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M1.7 74.539062h113.568"
            stroke-linecap="round"
          />
          <path
            d="M90.58 32.027062h19.632v41.264H90.58zM6.756 32.027062h19.632v41.264H6.756zM4.052 25.019062h25.04v6.112H4.052zM87.876 25.019062h25.04v6.112h-25.04zM25.684 18.011062h65.6v6.112h-65.6z"
          />
          <path
            stroke-linecap="round"
            d="M53.076 1.891062h13.52l-3.38 4.056 3.38 4.056h-13.52z"
          />
          <path
            d="M53.076 18.115062v-17.576M56.132 35.187062h4.704v14.168h-4.704zM45.316 35.187062h4.704v14.168h-4.704zM34.5 35.187062h4.704v14.168H34.5zM66.948 35.187062h4.704v14.168h-4.704zM77.764 35.187062h4.704v14.168h-4.704zM98.044 37.891062h4.704v14.168h-4.704zM98.044 59.523062h4.704v11.464h-4.704zM14.22 37.891062h4.704v14.168H14.22zM14.22 59.523062h4.704v11.464H14.22zM56.132 56.819062h4.704v14.168h-4.704zM45.316 56.819062h4.704v14.168h-4.704zM34.5 56.819062h4.704v14.168H34.5zM66.948 56.819062h4.704v14.168h-4.704zM77.764 56.819062h4.704v14.168h-4.704z"
          />
        </g>
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
  </div>
</template>
