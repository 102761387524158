// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createSSRApp } from 'vue'
import App from '@/App.vue'
import { startStore } from '@/store'
import { startRouter, afterVuePorg } from '@/router'
import createVuePorg from 'vue-porg'
import { startClient } from '@/api/client'

import { startI18n, setLocale } from '@/i18n'
import { createHead } from '@unhead/vue'
import 'dayjs/locale/pt'
import 'dayjs/locale/en'

import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import arraySupport from 'dayjs/plugin/arraySupport'
import '@/websocket'

import dayjs from 'dayjs'

dayjs.extend(relativeTime)
dayjs.extend(LocalizedFormat)
dayjs.extend(arraySupport)

/**
 * Creates a Vue application by integrating various modules:
 * - Creates a Vuex store for state management
 * - Initializes a router using the store
 * - Prepares an HTTP client (Axios)
 * - Configures internationalization
 * - Integrates necessary plugins
 * - Sets up the app with server-side rendering capabilities
 * @param {Array} cookies - An array of cookie objects
 * @param {object} options - An object containing options for the app
 * @param {string} options.lang - The language code for the app
 * @param {string} options.baseUrl - The base URL for the app
 * @returns {object} An object containing:
 *   - app: The Vue application instance
 *   - router: A configured Vue Router instance
 *   - i18n: Internationalization setup
 *   - store: The Vuex data store
 *   - axios: HttpClient configuration (Axios)
 *   - head: Function to generate the head section of the page
 */
export function createApp (cookies = null, { lang = 'pt', baseUrl }) {
  const i18n = startI18n(lang)
  const client = startClient(cookies)
  const store = startStore(client)
  const router = startRouter(store, client)

  const VuePorg = createVuePorg({
    parentRouteName: 'SearchPage',
    uiLayout: 'PageWithNavBarLayout',
    router,
    store,
    axios: client,
    isSSR: import.meta.env.SSR,
    notAuthorizedHandlerApi: () => {
      window.location.href = '/'
    },
    notAuthorizedHandlerRoute: (auth, user, { to, from, next }) => {
      if (from.path) {
      // the user was already inside the SPA so we can safely abort navigation
        if (app.config.globalProperties.$getInfoFromCookie()) {
          return next(false)
        } else if (!import.meta.env.SSR) {
          window.location.href = `/api/authenticate?callbackUrl=${encodeURIComponent(to.fullPath)}`
        }
      }
      // the user came from outside the SPA
      // e.g. typed the link on the url bar or clicked on a bookmark
      return next({ path: '/' })
    }
  })
  const head = createHead()

  const app = createSSRApp(App)
    .use(router)
    .use(i18n)
    .use(store)
    .use(VuePorg)
    .use(head)
  app.mixin({
    methods: {
      setLocale,
      sendNotification: function ({ key, message, params, type, persist }) {
        store.dispatch('setTopMessage', {
          active: true,
          msg: key
            ? {
                pt: this.$t(key, params || {}, { locale: 'pt' }),
                en: this.$t(key, params || {}, { locale: 'en' })
              }
            : message,
          dismiss: true,
          type,
          persist: persist || false
        })
      },
      clearNotification: function () {
        store.dispatch('setTopMessage', { active: false, msg: { pt: '', en: '' }, dismiss: false, type: '', persist: false })
      },
      notifyErrorWithKey: function (error, { type, persist } = {}) {
        if (error.key) {
          if (error.translations) {
            this.send({
              message: { pt: error.translations.pt, en: error.translations.en },
              type: type || 'danger',
              persist: persist || false
            })
          } else {
            this.sendNotification({ key: 'feedback-messages.error.internal', type: 'danger', persist: persist || false })
          }
        }
      },
      prettyBytes (num) {
        if (typeof num !== 'number' || isNaN(num)) {
          throw new TypeError('Expected a number')
        }

        const neg = num < 0
        const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        if (neg) {
          num = -num
        }

        if (num < 1) {
          return (neg ? '-' : '') + num + ' B'
        }

        const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
        num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
        const unit = units[exponent]

        return (neg ? '-' : '') + num + ' ' + unit
      }
    }
  })
  afterVuePorg(app, router, store, { baseUrl, lang })
  app.provide('$baseUrl', baseUrl)

  return { app, router, i18n, store, axios: client, head }
}

// app.config.errorHandler = async (error, vm, info) => {
//   if (error.response?.status === 401) return
//   console.error(error)
//   return vm.$router.push({ name: 'ErrorPage', params: { error } })
// }

// window.addEventListener('offline', () => {
//   vue.$notification.send({ key: 'no.network', type: 'warn', dismiss: false })
// }, false)

// window.addEventListener('online', () => {
//   if (store.state.topMessage.active && store.state.topMessage.msg === 'no.network') {
//     vue.$notification.dismiss()
//   }
// }, false)
