import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useUnitsAPI from '@/api/units'

import UnitPage from '@/pages/UnitPage.vue'
import UnitRecordsPage from '@/pages/UnitRecordsPage.vue'
import UnitMembersPage from '@/pages/UnitMembersPage.vue'
import UnitSubunitsPage from '@/pages/UnitSubunitsPage.vue'
import ExploreUnitsPage from '@/pages/ExploreUnitsPage.vue'

import DateHelpers from '@/mixins/DateHelpers'

/**
 *
 * @param axios
 */
export default function (axios) {
  const UnitsAPI = useUnitsAPI(axios)
  const NavigationGuards = useNavigationGuards(axios)

  return [
    {
      path: '/explore/units',
      name: 'ExploreUnitsPage',
      component: ExploreUnitsPage,
      meta: {
        pageInfo: 'explore.units',
        layout: 'PageWithNavBarAndFooterLayout'
      },
      props: route => ({
        rootUnit: route.meta.rootUnit,
        subUnitsPage: route.meta.subUnitsPage
      }),
      async beforeEnter (to, from, next) {
        try {
          const rootUnit = await NavigationGuards.fetchRootUnit()
          to.meta.rootUnit = rootUnit
        } catch (err) {
          if (err.response.data.key === 'unit-not-found') {
            to.meta.rootUnit = null
            to.meta.subUnitsPage = { totalItems: 0, items: [] }
            return next()
          }
        }
        const subUnitsPage = await NavigationGuards.fetchAllSubUnits({ parentId: to.meta.rootUnit.id })
        to.meta.subUnitsPage = subUnitsPage
        next()
      }
    },
    {
      path: '/units/:unitID',
      name: 'UnitPage',
      component: UnitPage,
      redirect: { name: 'UnitRecordsPage' },
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      },
      props: route => ({
        unitID: route.params.unitID,
        unit: route.meta.unit,
        scientificProduction: route.meta.scientificProduction
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          const [unit, scientificProduction] = await Promise.all([
            NavigationGuards.fetchUnit({ id: to.params.unitID, countView: true }),
            UnitsAPI.getScientificProduction({ unitID: to.params.unitID })
          ])
          const predecessors = await Promise.all(unit.predecessorUnits.map(predecessor => NavigationGuards.fetchUnit({ id: predecessor })))
          const successors = await Promise.all(unit.successorUnits.map(successor => NavigationGuards.fetchUnit({ id: successor })))
          to.meta.scientificProduction = scientificProduction
          to.meta.unit = unit
          to.meta.unit.predecessorUnits = predecessors
          to.meta.unit.successorUnits = successors
          next()
        }
      ),
      children: [
        {
          path: 'records',
          name: 'UnitRecordsPage',
          component: UnitRecordsPage,
          props: route => ({
            unitID: route.params.unitID,
            page: Number(route.query.page) || 1,
            q: route.query.q,
            type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
            start: route.query.start ? DateHelpers.methods.dateStringToObjectString(route.query.start) : null,
            end: route.query.end ? DateHelpers.methods.dateStringToObjectString(route.query.end) : null,
            perPage: Number(route.query.perPage) || undefined
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'members',
          name: 'UnitMembersPage',
          redirect: { name: 'UnitCurrentMembersPage' },
          component: UnitMembersPage,
          props: route => ({
            unitID: route.params.unitID,
            page: Number(route.query.page) || 1,
            perPage: Number(route.query.perPage) || undefined,
            q: route.query.q
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          },
          children: [
            {
              path: 'current',
              name: 'UnitCurrentMembersPage',
              component: UnitMembersPage
            },
            {
              path: 'past',
              name: 'UnitPastMembersPage',
              component: UnitMembersPage
            }
          ]
        },
        {
          path: 'subunits',
          name: 'UnitSubunitsPage',
          component: UnitSubunitsPage,
          props: route => ({
            unitID: route.params.unitID,
            page: Number(route.query.page) || 1,
            perPage: Number(route.query.perPage) || undefined,
            q: route.query.q
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          },
          beforeEnter (to, from, next) {
            if (!to.meta.unit.hasSubUnits) {
              return next({ name: 'PageNotFound', params: [to.path] })
            }
            next()
          }
        }
      ]
    }
  ]
}
