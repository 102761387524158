<template>
  <section class="researcher-records-page">
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('researcher.public.profile.advised-records.header') }}
        </h1>
        <p>{{ $t('researcher.public.profile.advised-records.description') }}</p>
      </div>
    </header>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :query="searchQuery"
      :page-data="records"
    >
      <template #search-bar="{buttonAttrs, inputAttrs}">
        <div class="f-field-search">
          <button
            class="f-field-search__submit"
            :aria-label="$t('search')"
            v-bind="buttonAttrs"
          />
          <input
            v-model.trim="searchQuery"
            :placeholder="$t('search.records')"
            type="search"
            class="f-field-search__input f-field-search__input--card"
            v-bind="inputAttrs"
          >
        </div>
      </template>
      <template #list-header="{ isLoading }">
        <AdvancedOptions>
          <template #main>
            <MultiStateCounter
              :is-loading="isLoading"
              :is-searching="isSearching"
              :count="records ? records.totalItems : 0"
              class="tab-navigation__counter"
            />
          </template>
          <template #options>
            <RecordTypesFilter
              v-model="typeFilters"
              :columns="3"
            />
          </template>
        </AdvancedOptions>
      </template>
      <template #list-skeleton>
        <RecordSkeleton
          :count="3"
          class="card-list"
        />
      </template>
      <template #list>
        <ListRecords
          :records="records.items"
          :compact="true"
          :highlight-titles="q === ''"
          @deleted="refreshPage"
        />
      </template>
      <template #pagination>
        <PaginationBar
          :current-page="currentPage"
          :total-items="records.totalItems"
          :items-per-page="perPage"
        />
      </template>
      <template #empty-state="{ isAlwaysEmpty }">
        <RecordEmptyState>
          <template #title>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.advised-records.empty.state.always.empty.header') }}
            </template>
            <template v-else-if="emptyStateSearchQuery">
              {{ $t('researcher.public.profile.advised-records.empty.state.search.results.header', { query: emptyStateSearchQuery }) }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.advised-records.empty.state.filter.results.header') }}
            </template>
          </template>
          <template #description>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.advised-records.empty.state.always.empty.description') }}
            </template>
            <template v-else-if="emptyStateSearchQuery">
              {{ $t('researcher.public.profile.advised-records.empty.state.search.results.description') }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.advised-records.empty.state.filter.results.description') }}
            </template>
          </template>
        </RecordEmptyState>
      </template>
    </SearchPaginatedList>
  </section>
</template>

<script>
import PaginationBar from '@/components/utils/PaginationBar.vue'
import RecordEmptyState from '@/components/empty-states/Record.vue'
import RecordSkeleton from '@/components/loading-skeletons/Record.vue'
import useResearchersAPI from '@/api/researchers'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import ListRecords from '@/components/search/results/ListRecords.vue'
import AdvancedOptions from '@/components/utils/AdvancedOptions.vue'
import RecordTypesFilter from '@/components/records/filters/RecordTypes.vue'
import MultiStateCounter from '@/components/utils/MultiStateCounter.vue'
import { useI18n } from 'vue-i18n'
import { useHeadSafe } from '@unhead/vue'

export default {
  components: {
    PaginationBar,
    RecordEmptyState,
    RecordSkeleton,
    SearchPaginatedList,
    ListRecords,
    AdvancedOptions,
    RecordTypesFilter,
    MultiStateCounter
  },
  inject: ['$axios'],
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.emptyStateSearchQuery = to.query.q

      this.currentPage = Number(to.query.page) || 1
      this.records = await this.ResearcherAPI.getAdvisedRecords({
        userId: this.userId, page: this.currentPage, perPage: this.perPage, q: to.query.q, recordTypes: this.typeFilters
      })
      this.isSearching = false
    }, 500)
    localStorage.setItem('perPage', Number(to.query.perPage))
    next()
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    q: {
      type: String,
      default: ''
    },
    type: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      default: import.meta.env.SSR ? 10 : Number(localStorage.getItem('perPage')) || 10
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          title: 'Publicações',
          description: 'Uma lista com todos as publicações orientadas por este autor'
        },
        en: {
          title: 'Publications',
          description: 'A list with all publications advised by this author'
        }
      }
    })
    const metaInfo = {
      title: t('title'),
      meta: [{ name: 'description', content: t('description') }]
    }
    useHeadSafe(metaInfo)
    return { t }
  },
  data () {
    return {
      ResearcherAPI: useResearchersAPI(this.$axios),
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      records: undefined,
      searchQuery: this.q,
      searchTimeout: undefined,
      typeFilters: this.type
    }
  },
  watch: {
    async searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return

      const query = { ...this.$route.query, q: newValue }
      if (newValue === '') {
        delete query.q
      }
      delete query.page
      await this.$router.replace({ query })
    },
    async typeFilters (newValue) {
      const query = { ...this.$route.query, type: newValue }
      delete query.page
      await this.$router.replace({ query })
    }
  },
  beforeUnmount () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.records = await this.ResearcherAPI.getAdvisedRecords({
      userId: this.userId, page: this.currentPage, perPage: this.perPage, q: this.q, recordTypes: this.typeFilters
    })
    this.isInitialLoading = false
    // purposefully makes search query non-reactive to display on empty state
    this.emptyStateSearchQuery = this.searchQuery
  },
  methods: {
    refreshPage () {
      // called after delete
      // we delay the refresh because the backend is eventually consistent
      this.isSearching = true
      setTimeout(async () => {
        try {
          await this.$router.replace({})
        } catch (err) {
          this.isSearching = true
          this.currentPage = 1
          this.records = await this.ResearcherAPI.getAdvisedRecords({
            userId: this.userId, page: this.currentPage, perPage: this.perPage, q: this.q, recordTypes: this.typeFilters
          })
          this.isSearching = false
        }
      }, 1000)
    }
  }
}
</script>
