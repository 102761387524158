<template>
  <div class="explore-units-page">
    <div class="container">
      <UnitEmptyState
        v-if="rootUnit === null"
      >
        <template #title>
          {{ $t('explore.units.empty.state.header') }}
        </template>
        <template #description>
          {{ $t('explore.units.empty.state.description') }}
        </template>
      </UnitEmptyState>
      <template v-else>
        <header class="card">
          <div class="card-row">
            <div class="card-row__text">
              <p>{{ $t(`unitTypes.${rootUnit.type}`) }}</p>
              <h1 class="card-row__title">
                {{ rootUnit.name }}
              </h1>
            </div>
          </div>
          <div class="card-row card-row--sm">
            <div class="card-row__text">
              <div class="btn-group">
                <RouterLink
                  :to="{ name: 'UnitPage', params: { unitID: rootUnit.id } }"
                  class="btn btn--sm btn--primary"
                >
                  {{ $t('explore.units.view.profile') }}
                </RouterLink>
              </div>
            </div>
          </div>
        </header>
        <template v-if="subUnitsPage && subUnitsPage.totalItems > 0">
          <div
            v-for="subUnit in subUnits"
            :key="subUnit.id"
            class="card"
          >
            <div class="card-row card-row--sm">
              <div class="card-row__text">
                <Tree
                  :level="2"
                  :value="subUnit"
                  @open="getSubUnits"
                >
                  <template #default="{ node: unit, isOpen, toggle, level }">
                    <p class="explore-units-page__unit-type">
                      {{ $t(`unitTypes.${unit.type}`) }}
                    </p>
                    <component
                      :is="level <= 6 ? `h${level}` : 'p'"
                      class="card-row__title h5 explore-units-page__unit-title"
                    >
                      <RouterLink
                        :to="{ name: 'UnitPage', params: { unitID: unit.id } }"
                        class="card-row__link explore-units-page__unit-link"
                      >
                        {{ unit.name }}
                      </RouterLink>
                      <button
                        v-if="unit.hasSubUnits"
                        class="toggle-btn"
                        @click="toggle"
                      >
                        <span :class="{ 'i-arrow-up': isOpen, 'i-arrow-down': !isOpen }" />
                      </button>
                      <span
                        v-if="loadingSet.has(unit.id)"
                        class="explore-units-page__loading-text small-caps"
                      >{{ $t('loading...') }}</span>
                    </component>
                  </template>
                </Tree>
              </div>
            </div>
          </div>
        </template>
        <UnitEmptyState
          v-else
          class="card explore-units-page__empty-state"
        >
          <template #title>
            {{ $t('explore.units.empty.state.subunits.header') }}
          </template>
          <template #description>
            {{ $t('explore.units.empty.state.subunits.description') }}
          </template>
        </UnitEmptyState>
      </template>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import { mapState, useStore } from 'vuex'
import Tree from '@/components/utils/Tree.vue'
import useUnitsAPI from '@/api/units'
import { fetchAllPages } from '@/utils/pagination-helpers'
import UnitEmptyState from '@/components/empty-states/Unit.vue'
import { useI18n } from 'vue-i18n'
import { useHeadSafe } from '@unhead/vue'

export default {
  components: {
    Tree,
    UnitEmptyState
  },
  inject: ['$axios'],
  props: {
    page: {
      type: Number,
      default: 1
    },
    rootUnit: {
      type: Object,
      default: null
    },
    subUnitsPage: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          title: 'Explorar unidades',
          description: 'Lista de todas as unidades da instituição {institutionName}'
        },
        en: {
          title: 'Explore units',
          description: 'List of all units in the institution {institutionName}'
        }
      }
    })
    const store = useStore()
    const metaInfo = {
      title: t('title'),
      meta: [{ name: 'description', content: t('description', { institutionName: store.state.configuration.institutionName }) }]
    }
    useHeadSafe(metaInfo)
    return { t }
  },
  data () {
    return {
      UnitsAPI: useUnitsAPI(this.$axios),
      subUnits: this.subUnitsPage.items,
      loadingSet: new Set()
    }
  },
  computed: {
    ...mapState(['configuration'])
  },
  methods: {
    async getSubUnits (unit) {
      if (unit.children) {
        return
      }

      try {
        this.loadingSet.add(unit.id)
        const unitsPage = await fetchAllPages(page => this.UnitsAPI.getSubUnits({ unitID: unit.id, page, perPage: 20 }))
        unit.children = unitsPage.items
        // Vue.set(unit, 'children', unitsPage.items)
      } finally {
        this.loadingSet.delete(unit.id)
      }
    }
  }
}
</script>
<style lang="scss">
@use "@/assets/scss/variables" as *;

.explore-units-page {
  padding: 3rem 0;
}

.explore-units-page__empty-state {
  border: none;
  background-color: white;
}

.tree__children {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 2px solid $light-gray;
}

.tree__child + .tree__child {
  margin-top: 1rem;
}

.explore-units-page__unit-type {
  font-size: 0.8125rem;
}

.toggle-btn {
  appearance: none;
  border: 0.125rem solid $light-blue-700;
  margin: 0 0.5rem;
  padding: 0.3125rem;
}

.explore-units-page__loading-text {
  color: $blue;
  font-family: $primary-font;
  @media screen and (width >= 60rem) {
    margin-left: 0.5rem;
  }
}

.explore-units-page__unit-link {
  font-family: $primary-font;
  font-weight: 600;
  color: $slate;
}
</style>
