<template>
  <div class="scientific-production__container" />
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          tooltip: {
            xAxis: 'Ano:',
            yAxis: 'Publicações:'
          }
        },
        en: {
          tooltip: {
            xAxis: 'Year:',
            yAxis: 'Publications:'
          }
        }
      }
    })
    return { t }
  },
  data () {
    return {
      isFirstRender: true
    }
  },
  computed: {
    production () {
      return [...this.data].sort((a, b) => a.year - b.year)
    }
  },
  watch: {
    production () {
      this.renderChart()
    },
    '$i18n.locale' () {
      this.renderChart()
    }
  },
  mounted () {
    this.renderChart()
    const resizeObserver = new ResizeObserver(() => { this.renderChart() })
    resizeObserver.observe(this.$el)
  },
  methods: {
    async renderChart () {
      const years = this.production.map(d => d.year)
      const totalRecords = this.production.map(d => d.totalRecords)
      const maxTotalRecords = Math.max(...totalRecords)

      const yAxis = {
        title: false
      }
      if (maxTotalRecords < 5) {
        yAxis.max = 5
      }

      const HighCharts = await import('highcharts')
      HighCharts.chart(this.$el, {
        chart: {
          type: 'column',
          spacingLeft: 0,
          spacingRight: 0,
          spacingBottom: 0,
          events: {
            click: (e) => {
              const column = Math.abs(Math.round(e.xAxis[0].value))
              const year = years[column]
              this.$router.replace({ query: { start: `${year}-1-1`, end: `${year}-12-31` } })
            }
          }
        },
        title: false,
        credits: false,
        legend: false,
        xAxis: {
          categories: years,
          crosshair: true
        },
        yAxis,
        plotOptions: {
          series: {
            color: 'var(--light-blue)',
            borderColor: 'var(--light-blue-700)',
            borderRadius: 2,
            borderWidth: 1,
            pointPadding: 0.05,
            groupPadding: 0.15,
            animation: false,
            states: {
              hover: {
                color: 'var(--light-blue-600)'
              }
            }
          },
          column: {
            events: {
              click: (e) => {
                const year = e.point.category
                this.$router.replace({ query: { start: `${year}-1-1`, end: `${year}-12-31` } })
              }
            }
          }
        },
        tooltip: {
          headerFormat: `<span style="font-size: 10px">${this.t('tooltip.xAxis')} {point.key}</span><br>`,
          pointFormat: `<span style="font-size: 10px"><b>${this.t('tooltip.yAxis')} {point.y}</b></span>`,
          shared: true,
          useHTML: true,
          outside: true
        },
        series: [
          {
            data: totalRecords
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;

.scientific-production__container {
  height: 175px;
  width: 100%;
}

.scientific-production__container:deep(svg) {
  rect.bar {
    fill: $light-blue;
    stroke: $light-blue-700;
  }

  .axis {
    .domain {
      stroke: $light-blue-700;
    }
  }
  .axis__label--x {
    text {
      fill: $gray;
      font-size: 12px;
    }
  }
}

</style>
