import { createStore } from 'vuex'

import * as getters from './getters'
import actions from './actions'
import mutations from './mutations'

let store

/**
 *
 * @param axios
 */
export function startStore (axios) {
  const state = {
    topMessage: { active: false, msg: { pt: ' ', en: '' }, dismiss: false, type: '' },
    profile: {},
    managedUnits: [],
    externalProfiles: undefined,
    configuration: {},
    progressBar: 0
  }

  store = createStore({
    state,
    getters,
    actions: actions(axios),
    mutations
  })
  return store
}
