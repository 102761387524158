<template>
  <div style="padding-bottom: 1rem;">
    <div class="tab-navigation">
      <slot name="main" />

      <ul class="tab-navigation__options">
        <li class="tab-navigation__options-item">
          <button
            class="tab-navigation__options-link"
            :class="{ 'trigger--closed': !showOptions, 'trigger--open': showOptions }"
            @click="showOptions = !showOptions"
          >
            {{ $t('advanced.options') }}
          </button>
        </li>
      </ul>
    </div>
    <transition name="slide">
      <section
        v-show="showOptions"
        class="card card__section"
      >
        <slot name="options" />
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showOptions: false
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;
@use "@/assets/scss/animations" as *;

.trigger {
  &::after {
    content: "";
    position: absolute;
    top: 0.5rem;
    right: 0;
    margin-left: 6px;
    width: 0;
    height: 0;
  }
}

.trigger--closed {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: currentcolor transparent transparent;
  }
}

.trigger--open {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 0 4px 4px;
    border-color: transparent transparent currentcolor;
  }
}
</style>
