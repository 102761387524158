/**
 * username API, used for operations with username
 * @param {import('axios').AxiosInstance} client The instance of the axios client
 */
export default function (client) {
  return {
    /**
     *
     * @param {object} root0 Root object
     * @param {string} root0.username Username of the user to get the userId
     */
    getUserIdFromUsername: async ({ username }) => {
      const response = await client.get('/usernames', {
        params: {
          username
        }
      })
      return response.data
    }
  }
}
