import underscore from 'underscore'
import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useResearchersAPI from '@/api/researchers'
import { types as recordTypes } from '@scholar/metadata-utils/main'

import ResearcherPage from '@/pages/ResearcherPage.vue'
import ResearcherRecordsPage from '@/pages/ResearcherRecordsPage.vue'
import ResearcherAdvisedRecordsPage from '@/pages/ResearcherAdvisedRecordsPage.vue'
import ResearcherAffiliationsPage from '@/pages/ResearcherAffiliationsPage.vue'
import ResearcherCoAuthorsPage from '@/pages/ResearcherCoAuthorsPage.vue'
import ResearcherBioPage from '@/pages/ResearcherBioPage.vue'

import DateHelpers from '@/mixins/DateHelpers'

/**
 *
 * @param {import('axios').AxiosInstance} axios An instance of Axios to make requests
 * @returns {Array} An array of routes for authors and their associated pages
 */
export default function (axios) {
  const NavigationGuards = useNavigationGuards(axios)
  const ResearchersAPI = useResearchersAPI(axios)
  return [
    {
      path: '/u/:username',
      beforeEnter: async (to, from, next) => {
        return next({ name: 'ResearcherPage', params: { userId: to.params.username } })
      }
    },
    {
      path: '/authors/:userId',
      name: 'ResearcherPage',
      component: ResearcherPage,
      redirect: { name: 'ResearcherRecordsPage' },
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      },
      props: route => ({
        author: route.meta.author,
        scientificProduction: route.meta.scientificProduction
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          const [author, scientificProduction] = await Promise.all([
            NavigationGuards.fetchAuthorByUserId({ userId: to.params.userId, countView: true }),
            ResearchersAPI.getScientificProduction({ userId: to.params.userId })
          ])
          to.meta.author = author
          to.meta.scientificProduction = scientificProduction
          next()
        }
      ),
      children: [
        {
          path: 'records',
          name: 'ResearcherRecordsPage',
          component: ResearcherRecordsPage,
          props: route => ({
            userId: route.params.userId,
            page: Number(route.query.page) || 1,
            q: route.query.q,
            type: underscore.intersection(recordTypes,
              typeof route.query.type === 'string' ? [route.query.type] : route.query.type),
            perPage: Number(route.query.perPage) || undefined,
            start: route.query.start ? DateHelpers.methods.dateStringToObjectString(route.query.start) : null,
            end: route.query.end ? DateHelpers.methods.dateStringToObjectString(route.query.end) : null
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'supervised-records',
          name: 'ResearcherAdvisedRecordsPage',
          component: ResearcherAdvisedRecordsPage,
          props: route => ({
            userId: route.params.userId,
            page: Number(route.query.page) || 1,
            q: route.query.q,
            perPage: Number(route.query.perPage) || undefined,
            type: underscore.intersection(recordTypes,
              typeof route.query.type === 'string' ? [route.query.type] : route.query.type)
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'affiliations',
          name: 'ResearcherAffiliationsPage',
          component: ResearcherAffiliationsPage,
          props: route => ({
            userId: route.params.userId,
            page: Number(route.query.page) || 1,
            perPage: Number(route.query.perPage) || undefined
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'co-authors',
          name: 'ResearcherCoAuthorsPage',
          component: ResearcherCoAuthorsPage,
          props: route => ({
            userId: route.params.userId,
            page: Number(route.query.page) || 1,
            q: route.query.q,
            perPage: Number(route.query.perPage) || undefined
          }),
          meta: {
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'bio',
          name: 'ResearcherBioPage',
          component: ResearcherBioPage,
          props: route => ({
            userId: route.params.userId
          })
        }
      ]
    }
  ]
}
