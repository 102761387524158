<template>
  <div
    v-if="type || showInvalidWarning"
    class="record-field"
  >
    <div class="rights-part__section">
      <div :class="{ 'invalid-field': showInvalidWarning }">
        <h2 class="h5--ssp">
          {{ $t('fields.record.rights.label') }}:
        </h2>
        <div
          v-if="showInvalidWarning"
          class="invalid-field__warn"
        >
          <svg
            height="18"
            width="18"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="var(--orange)"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                stroke-width="1.046"
                cx="9"
                cy="9"
                r="8.477"
              />
              <path
                d="M7.2 6.514c0-1.254.99-1.984 1.98-2.013.99-.03 1.98.66 1.98 1.894 0 1.999-2.148 1.122-2.2 3.625"
                stroke-width="1.046"
                stroke-linecap="round"
              />
              <path
                d="M9 13.185a.54.54 0 1 1 0-1.08.54.54 0 0 1 0 1.08Z"
                stroke-width=".25"
                fill="var(--orange)"
              />
            </g>
          </svg>
          <span>
            {{ $t('record.view.inconsistency.label') }}
          </span>
        </div>
      </div>
      <p v-if="rights">
        {{ $t(`fields.record.rights.values.${rights}`) }}
      </p>
      <p v-else>
        {{ $t('record.view.inconsistency.value') }}
      </p>
    </div>
    <template v-if="type === 'embargoed-access'">
      <div class="rights-part__section">
        <h2
          class="card__section-title h5--ssp"
          :aria-label="$t('fields.record.dateAvailable.label')"
        >
          {{ $t('fields.record.dateAvailable.label') }}:
        </h2>
        <p>
          {{ generateDateString(dateAvailable) }}
        </p>
      </div>

      <div
        v-if="embargofct"
        class="rights-part__section"
      >
        <h2
          class="card__section-title h5--ssp"
          :aria-label="$t('fields.record.embargofct.label')"
        >
          {{ $t('fields.record.embargofct.label') }}:
        </h2>
        <p>
          {{ embargofct }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import DateHelpers from '@/mixins/DateHelpers'
import dayjs from 'dayjs'

export default {
  mixins: [DateHelpers],
  inject: ['ignoredFields'],
  props: {
    type: {
      type: String,
      default: null
    },
    dateAvailable: {
      type: Object,
      default: () => {}
    },
    embargofct: {
      type: String,
      default: null
    }
  },
  computed: {
    invalid () {
      if (this.ignoredFields) {
        return this.ignoredFields.includes('rights')
      }
      return false
    },
    showInvalidWarning () {
      return this.invalid && this.$auth?.isLogged()
    },
    rights () {
      if (this.type && this.dateAvailable) {
        const dateAvailable = dayjs(`${this.dateAvailable.year}-${this.dateAvailable.month}-${this.dateAvailable.day}`)
        if (this.type === 'embargoed-access' && dateAvailable.isBefore(dayjs())) return 'embargo-lifted'
      }
      return this.type
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/record" as *;

.rights-part__section + .rights-part__section {
  margin-top: 2rem;
}

</style>
