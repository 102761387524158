<template>
  <Component
    :is="`${record.type}-view`"
    v-if="record.creators"
    :record="record"
    layout="SingleRecordLayout"
  >
    <template #sideCard>
      <slot name="sideCard" />
    </template>
  </Component>
</template>

<script>
import MastersThesis from '@/components/records/views/MastersThesis.vue'
import Article from '@/components/records/views/Article.vue'
import Lecture from '@/components/records/views/Lecture.vue'
import Book from '@/components/records/views/Book.vue'
import BookPart from '@/components/records/views/BookPart.vue'
import Report from '@/components/records/views/Report.vue'
import Conference from '@/components/records/views/Conference.vue'
import DoctoralThesis from '@/components/records/views/DoctoralThesis.vue'
import Other from '@/components/records/views/Other.vue'
import Patent from '@/components/records/views/Patent.vue'

import DateHelpers from '@/mixins/DateHelpers'
import { useI18n } from 'vue-i18n'
import { useHeadSafe } from '@unhead/vue'
import { inject, provide } from 'vue'

export default {
  components: {
    'master-thesis-view': MastersThesis,
    'journal-article-view': Article,
    'lecture-view': Lecture,
    'book-view': Book,
    'book-part-view': BookPart,
    'report-view': Report,
    'other-view': Other,
    'conference-object-view': Conference,
    'doctoral-thesis-view': DoctoralThesis,
    'patent-view': Patent
  },
  mixins: [DateHelpers],
  props: {
    record: {
      required: true,
      type: Object
    }
  },
  setup (props) {
    provide('ignoredFields', props.record?.metadata?.ignored ?? [])
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          description: 'Página com os meta dados da publicação {title}'
        },
        en: {
          description: 'Page with all metadata related to the publication "{title}"'
        }
      }
    })
    const creatorsMeta = props.record.creators.map(c => {
      return { name: 'citation_author', content: c.name }
    })
    const meta = [
      { name: 'description', content: t('description', { title: props.record.title }) }, // Basic information required from Search engines
      { name: 'citation_title', content: props.record.title }, // Google Scholar minimum requirements
      ...creatorsMeta // Google Scholar minimum requirements
      // { name: 'citation_publication_date', content: DateHelpers.methods.generateDateString(props.record.dateIssued) } // Google Scholar minimum requirements
    ]
    if (props.record.citationTitle) meta.push({ name: 'citation_journal_title', content: props.record.citationTitle })
    if (props.record.citationVolume) meta.push({ name: 'citation_volume', content: props.record.citationVolume })
    if (props.record.citationIssue) meta.push({ name: 'citation_issue', content: props.record.citationIssue })
    if (props.record.citationStartPage) meta.push({ name: 'citation_firstpage', content: props.record.citationStartPage })
    if (props.record.citationEndPage) meta.push({ name: 'citation_lastpage', content: props.record.citationEndPage })
    if (props.record.files) {
      props.record.files.forEach(f => {
        // HOTFIX: This is a temporary solution to handle the PDF URL in the RecordPage.vue component
        meta.push({ name: 'citation_pdf_url', content: `https://scholar.tecnico.ulisboa.pt/api/records/${props.record.metadata.id}/file/${f.id}` })
      })
    }
    const metaInfo = {
      title: props.record.title,
      meta,
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: props.record.title,
          description: props.record.description,
          inLanguage: props.record.language,
          author: props.record.creators.filter(c => c.userId).map(creator => ({
            '@type': 'Person',
            name: creator.name,
            image: creator.user?.avatar,
            url: `${inject('$baseUrl')}/authors/${creator.userId}`
          }))
        }
      }]
    }
    useHeadSafe(metaInfo)
    return { t, meta }
  },
  data () {
    return {
      customSubtitleFields: []
    }
  },
  methods: {
    assignCustomSubtitleFields (value) {
      this.customSubtitleFields = value
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/variables" as *;

.publication {
  padding: 1.5rem 0;
}

.publication .card + .card {
  margin-top: 1rem;
}

.publication .card-row {
  padding: 1.5rem;
}

.publication-main {
  margin-top: 1rem;
}

.publication-main__left {
  margin-bottom: 1rem;
}

.publication__title {
  margin: 1rem 0 0.5rem;
}

.download-item {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}

.download-item + .download-item {
  margin-top: 1rem;
}

.download-icon {
  margin-right: 1rem;
}

.download-item__text {
  word-break: break-all;
}

.download-item--locked {
  display: block;
  position: relative;
}

@media (width >= 75rem) {
  .publication {
    padding: 3.5rem 0;
  }

  .publication-main {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    flex-grow: 1;
    margin-top: 1rem;
  }

  .publication-main__left {
    flex-basis: 47.75rem;
    width: 47.75rem;
    margin: 0 1rem 0 0;
  }
  .publication-main__right {
    flex-basis: 22.5rem;
    min-width: 22.5rem;
    flex-grow: 1;
  }
}
</style>
