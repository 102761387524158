<template>
  <FundingReferenceItem
    v-for="funding in fundingReferences"
    :key="funding"
    class="record-field"
    :funding="funding"
  />
</template>

<script>
import FundingReferenceItem from '@/components/records/views/parts/FundingReferenceItem.vue'

export default {
  components: {
    FundingReferenceItem
  },
  props: {
    fundingReferences: {
      type: Array,
      default: () => []
    }
  }
}
</script>
