<template>
  <div class="views__header-part">
    <Record
      :record="record"
      :clickable="false"
      :editable="showActions"
      :removable="showActions"
      tag="div"
      record-title-tag="h1"
      @delete="openDeleteConfirmationModal"
    >
      <slot />
    </Record>
    <delete-record-confirmation
      v-if="showActions"
      v-model="showDeleteConfirmationModal"
      :record="record"
      @deleted="recordDeleted"
    />
  </div>
</template>

<script>
import Record from '@/components/records/Record.vue'
import DeleteRecordConfirmation from '@/components/records/DeleteRecordConfirmation.vue'
import { isRecordAuthor, isUnitManagerOfAuthorMemberships } from '@/utils/record-helpers'
import { mapState } from 'vuex'

export default {
  filters: {
    upperFirstCharacter (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  components: {
    Record,
    DeleteRecordConfirmation
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDeleteConfirmationModal: false
    }
  },
  computed: {
    ...mapState(['profile']),
    datetime () {
      return this.generateDateString(this.record[this.presentedDate])
    },
    showActions () {
      return this.$auth?.isLogged() && !!this.record.metadata && (
        this.profile.roles?.includes('operator') ||
        isRecordAuthor({ record: this.record, userId: this.profile.id }) ||
        isUnitManagerOfAuthorMemberships({ record: this.record, profile: this.profile })
      )
    }
  },
  methods: {
    async openDeleteConfirmationModal () {
      this.showDeleteConfirmationModal = true
    },
    recordDeleted () {
      this.$router.push({ path: '/' }) // FIXME: maybe somewhere else?
    }
  }
}
</script>
<style lang="scss">
.views__header-part {
  .record__item-title {
    font-size: 1.6875rem;
    margin-bottom: 0.375rem;
  }
  .publication__alternative-title {
    margin: 0 0 0.5rem;
    font-weight: 400;
  }
}
</style>
