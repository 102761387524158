import Settings from '@/router/private/settings'
import Records from '@/router/private/records'
import { getUserRolesFromCookie, guardWithErrorHandling } from '@/router/navigation-guards'
import useRecordsAPI from '@/api/records'
import useProfileAPI from '@/api/profile'
import useUserTasksAPI from '@/api/user-tasks'
import dayjs from 'dayjs'

const CreateRecordPage = async () => import('@/pages/CreateRecordPage.vue')
const MergeRecordsPage = async () => import('@/pages/MergeRecordsPage.vue')
const SetupAccountPage = async () => import('@/pages/SetupAccountPage.vue')

const NegotiateOAuthPage = async () => import('@/pages/NegotiateOAuthPage.vue')
const EmailVerificationPage = async () => import('@/pages/EmailVerificationPage.vue')
const ReportRecordPage = async () => import('@/pages/ReportRecordPage.vue')
const NotificationsPage = async () => import('@/pages/NotificationsPage.vue')
const NegotiateCienciaIdPage = async () => import('@/pages/NegotiateCienciaIdPage.vue')

/**
 *
 * @param store
 * @param axios
 */
export default function (store, axios) {
  const RecordsAPI = useRecordsAPI(axios)
  const ProfileAPI = useProfileAPI(axios)
  const UserTasksAPI = useUserTasksAPI(axios)

  /** @type {import('vue-router').RouteRecord[]} */
  return [
    ...Settings(store, axios),
    ...Records(axios),
    {
      path: '/negotiate-oauth/:provider',
      name: 'NegotiateOAuthPage',
      component: NegotiateOAuthPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: route => {
        return {
          provider: route.params.provider,
          nonce: route.query.nonce,
          code: route.query.code
        }
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('fetchExternalProfiles')
        next()
      }
    },
    {
      path: '/negotiate-ciencia-id/:provider',
      name: 'NegotiateCienciaIdPage',
      component: NegotiateCienciaIdPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: route => {
        return {
          provider: route.params.provider
        }
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('fetchExternalProfiles')
        next()
      }
    },
    {
      path: '/account/setup',
      name: 'SetupAccountPage',
      component: SetupAccountPage,
      meta: {
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({
        profile: route.meta.profile,
        configuration: route.meta.configuration
      }),
      async beforeEnter (to, from, next) {
        // TODO: error handling
        const [profile, emails] = await Promise.all([ProfileAPI.get(), ProfileAPI.getEmails()])
        if (!profile?.roles?.includes('pre-setup-author') && !profile?.roles?.includes('pre-opt-in-author')) {
          return next({ path: '/', replace: false })
        }
        const { email: primaryEmail } = emails.find(e => e.primary)
        profile.email = primaryEmail
        to.meta.profile = profile
        to.meta.configuration = store.state.configuration
        next()
      }
    },
    {
      path: '/notifications',
      name: 'NotificationsPage',
      component: NotificationsPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({
        notifications: route.meta.notifications,
        page: route.params.page,
        perPage: route.params.perPage,
        now: route.params.now
      }),
      async beforeEnter (to, from, next) {
        to.params.page = 1
        to.params.perPage = 10
        to.params.now = dayjs()
        const notifications = await ProfileAPI.getNotifications({
          page: to.params.page,
          perPage: to.params.perPage,
          untilTimestamp: to.params.now.toISOString()
        })
        to.meta.notifications = notifications
        next()
      }
    },
    {
      path: '/email-verification',
      name: 'EmailVerificationPage',
      component: EmailVerificationPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({
        email: route.query.email,
        token: route.query.token
      })
      // TODO: beforeEnter that redirects to / if email or token are not defined
    },
    {
      path: '/create-record',
      name: 'CreateRecordPage',
      component: CreateRecordPage,
      meta: {
        layout: 'PageWithNavBarLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          const roles = getUserRolesFromCookie()
          const profile = store.state.profile
          if (roles.includes('author') || roles.includes('operator') || (profile.units ?? []).length > 0) {
            return next()
          }
          return next({ ...(from ?? { path: '/' }), replace: true })
        }
      )
    },
    {
      path: '/merge-records',
      name: 'MergeRecordsPage',
      component: MergeRecordsPage,
      meta: {
        layout: 'PageWithNavBarLayout'
      },
      props: route => ({
        taskId: route.query.taskId,
        task: route.meta.task,
        records: route.meta.records ?? [],
        mode: route.meta.mode ?? 'manual'
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (!roles.includes('author') && !roles.includes('operator')) {
            return next({ ...(from ?? { path: '/' }), replace: true })
          }
          if (to.query.taskId) {
            const task = await UserTasksAPI.get({ id: to.query.taskId })
            to.meta.task = task
            to.meta.mode = task.type
            const records = await Promise.all(
              task.records.map(record => RecordsAPI.get({ id: record.metadata.id, allowDraft: true }))
            )
            to.meta.records = records
            return next()
          }
          to.meta.mode = 'manual'
          next()
        })
    },
    {
      path: '/records/:id/report',
      name: 'ReportRecordPage',
      component: ReportRecordPage,
      meta: {
        layout: 'PageWithNavBarLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({
        record: route.meta.record
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          to.meta.record = await RecordsAPI.get({ id: to.params.id })
          next()
        }
      )
    }
  ]
}
