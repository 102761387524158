import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'

import RecordPage from '@/pages/RecordPage.vue'

/**
 * Cretaes routes related to records and their associated pages
 * @param {import('axios').AxiosInstance} axios An instance of Axios to make requests
 * @returns {Array} An array of routes for records and their associated pages
 */
export default function (axios) {
  const NavigationGuards = useNavigationGuards(axios)
  return [
    {
      path: '/records/:id',
      name: 'RecordPage',
      component: RecordPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      },
      props: route => ({
        record: route.meta.record
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          to.meta.record = await NavigationGuards.fetchRecord({ id: to.params.id, countView: true })
          next()
        }
      )
    }
  ]
}
