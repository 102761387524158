<template>
  <div>
    <p class="h5--ssp funding-reference-item__header">
      {{ t('fields.record.fundingReferences.label') }}
    </p>
    <p
      :class="{ 'funding-reference-item__funder-name' :
        funding.awardTitle ||funding.fundingStream ||funding.funderIdentifier ||funding.funderIdentifierType ||funding.awardTitle || funding.awardUri }"
    >
      {{ funding.funderName.trim() }}
    </p>
    <p v-if="funding.awardTitle">
      <span class="funding-reference-item__label">{{ t('fields.record.fundingReferences.awardTitle.label') }}:</span> {{ funding.awardTitle }}
    </p>
    <p v-if="funding.awardUri">
      <a
        rel="noreferrer"
        :href="funding.awardUri"
        class="u-text-blue"
      >
        {{ t('funderLink') }}
      </a>
    </p>
    <p v-if="funding.fundingStream">
      <span class="funding-reference-item__label">{{ t('fields.record.fundingReferences.fundingStream.label') }}:</span> {{ funding.fundingStream.trim() }}
    </p>
    <p v-if="funding.funderIdentifier">
      <span class="funding-reference-item__label">{{ t('fields.record.fundingReferences.funderIdentifier.label') }}:</span> {{ funding.funderIdentifier.trim() }}
    </p>
    <p v-if="funding.funderIdentifierType">
      <span class="funding-reference-item__label">{{ t('fields.record.fundingReferences.funderIdentifierType.label') }}:</span> {{ funding.funderIdentifierType.trim() }}
    </p>
    <p v-if="funding.awardNumber">
      <span class="funding-reference-item__label">{{ t('fields.record.fundingReferences.awardNumber.label') }}:</span> {{ funding.awardNumber.trim() }}
    </p>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n'

export default {
  props: {
    funding: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          funderLink: 'Visitar projeto'
        },
        en: {
          funderLink: 'Visit project'
        }
      }
    })
    return { t }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;

.funding-reference-item__header {
  margin-bottom: 0;
}
.funding-reference-item__funder-name {
  margin-bottom: 1rem;
}
.funding-reference-item__label {
  color: $dark;
  font-weight: 600;
}
</style>
