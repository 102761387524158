import underscore from 'underscore'

import { types as recordTypes } from '@scholar/metadata-utils/main'
import useResearchersAPI from '@/api/researchers'
import useUsersAPI from '@/api/users'

import ScientificInterestsPage from '@/pages/ScientificInterestsPage'
import SearchPage from '@/pages/SearchPage'
import UnsubscribeEmail from '@/pages/UnsubscribeEmailPage'
import ErrorPage from '@/pages/ErrorPage.vue'

import Authors from '@/router/public/authors'
import Records from '@/router/public/records'
import Units from '@/router/public/units'

// This page is very due to the advanced search and does not need to indexed
const SearchResultsPage = async () => import('@/pages/SearchResultsPage.vue')

/**
 * Set public routes
 * @param {import('axios').AxiosInstance} axios An instance of Axios for making HTTP requests to the API
 * @returns {import('vue-router').Route[]} An array of routes that are public and accessible without authentication
 */
export default function (axios) {
  const ResearchersAPI = useResearchersAPI(axios)
  const UsersAPI = useUsersAPI(axios)
  /** @type {import('vue-router').Route[]} */
  return [
    {
      path: '/',
      name: 'SearchPage',
      component: SearchPage,
      meta: {
        pageInfo: 'search',
        layout: 'PageWithNavBarAndFooterLayout'
      }
    },
    {
      path: '/interests',
      name: 'ScientificInterestsPage',
      component: ScientificInterestsPage,
      props: route => ({
        page: Number(route.query.page) || 1,
        authors: route.meta.authors,
        q: route.query.q,
        perPage: Number(route.query.perPage) || undefined
      }),
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      async beforeEnter (to, from, next) {
        const page = to.query.page || 1
        to.params.perPage = 10
        if (!to.query.q) {
          return next({ ...(from ?? { path: '/' }), replace: true })
        }
        to.meta.authors = await ResearchersAPI.listByScientificInterest({ scientificInterest: to.query.q, page, perPage: to.params.perPage })
        next()
      }
    },
    {
      path: '/search',
      name: 'SearchResultsPage',
      component: SearchResultsPage,
      props: route => ({
        q: route.query.q,
        domain: route.query.domain,
        page: Number(route.query.page) || 1,
        perPage: Number(route.query.perPage) || undefined,
        type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
        sort: route.query.sort,
        openAdvancedSearch: !!route.query.advancedSearchOpen
      }),
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      beforeEnter (to, from, next) {
        if (!['records', 'units', 'authors'].includes(to.query.domain)) {
          // unknown domain
          return next({ name: 'SearchPage' })
        }
        const type = typeof to.query.type === 'string' ? [to.query.type] : to.query.type
        if (underscore.without(type, ...recordTypes).length > 0) {
          // there are unknown types in the query params
          return next({ name: 'SearchPage' })
        }
        next()
      }
    },
    {
      path: '/unsubscribe-reminder-email',
      name: 'Unsubscribe',
      component: UnsubscribeEmail,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      beforeEnter: async (to, from, next) => {
        await UsersAPI.unsubscribeEmail(to.query.token)
        next()
      }
    },
    {
      path: '/error-page',
      name: 'ErrorPage',
      component: ErrorPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      },
      props: route => ({ error: route.params.error }),
      beforeEnter: (to, from, next) => {
        if (!!to.params.error && to.params.error instanceof Error) {
          next()
        }
      }
    },
    ...Authors(axios),
    ...Records(axios),
    ...Units(axios)
  ]
}
