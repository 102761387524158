<template>
  <div>
    <div class="tree__node">
      <slot
        :node="value"
        :is-open="isOpen"
        :toggle="toggleNode"
        :level="level"
        name="default"
      />
    </div>
    <div
      v-if="children.length > 0"
      v-show="isOpen"
      class="tree__children"
    >
      <tree
        v-for="child in children"
        :key="child"
        class="tree__child"
        :level="level + 1"
        :value="child"
        @open="$emit('open', $event)"
        @close="$emit('close', $event)"
      >
        <!-- Pass the default slot implementation recursively -->
        <Component
          :is="$slots.default"
          class="tree_node"
          :node="child"
          :is-open="isOpen"
          :toggle="toggleNode"
          :level="level"
          name="default"
        />
      </tree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tree',
  props: {
    value: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    }
  },
  emits: ['open', 'close'],
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    children () {
      return this.value.children ?? []
    }
  },
  methods: {
    toggleNode () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('open', this.value)
      } else {
        this.$emit('close', this.value)
      }
    }
  }
}
</script>
