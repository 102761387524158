<template>
  <div
    class="empty-state"
  >
    <div class="empty-state__illustration">
      <svg
        width="71"
        height="84"
        viewBox="0 0 71 84"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fill-rule="evenodd"
        >
          <path d="M-885-522H555V760H-885z" />
          <g
            stroke-width="2.88"
            stroke="var(--gray)"
          >
            <path
              d="M5.26 1.68h60.48c1.59058 0 2.88 1.28942 2.88 2.88v74.88c0 1.59058-1.28942 2.88-2.88 2.88H5.26c-1.59058 0-2.88-1.28942-2.88-2.88V4.56c0-1.59058 1.28942-2.88 2.88-2.88zM13.9 59.28h43.2M13.9 66.84h43.2M13.9 52.08h43.2M13.9 44.88h43.2M13.9 37.68h43.2M13.9 30.48h21.6"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M48.46 1.68l20.16 20.16H48.46z"
            />
          </g>
        </g>
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
  </div>
</template>
