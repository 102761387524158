import axios from 'axios'

export const baseURLBackend = import.meta.env.SSR ? 'http://localhost:8000/api/' : '/'
export const baseURLFrontend = import.meta.env.SSR ? 'http://localhost:8080/' : '/'

/**
 *
 * @param {import('axios').AxiosInstance} client The instance of the axios client
 */
export default function (client) {
  return {
    /**
     *  Fetch dynamic configuration from the backend server
     */
    get: async () => {
      const response = await axios.get(baseURLBackend + 'configuration')
      return response.data
    },
    /**
     *  Fetch static configuration, uses axios directly because configuration is not under /api/
     */
    getStaticConfig: async () => {
      const response = await axios.get(baseURLFrontend + 'configuration.json')
      return response.data
    },
    getTranslations: async () => {
      const response = await axios.get(baseURLFrontend + 'translations.json')
      return response.data
    },
    sendErrorReport: async (email, subject, description, exceptionInfo) => {
      const body = {
        message: exceptionInfo
      }
      if (email) body.email = email
      if (subject) body.subject = subject
      if (description) body.description = description

      client.post('error-report', body)
    }
  }
}
