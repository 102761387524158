import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useProfileAPI from '@/api/profile'
import underscore from 'underscore'
import { types as recordTypes } from '@scholar/metadata-utils/main'
import { isRecordAuthor, isUnitManagerOfAuthorMemberships } from '@/utils/record-helpers'
// import Vue from 'vue'

const EditRecordPage = async () => import('@/pages/EditRecordPage.vue')
const EditProfilePage = async () => import('@/pages/EditProfilePage.vue')
const ManageEmailsPage = async () => import('@/pages/ManageEmailsPage.vue')
const ManageAliasesPage = async () => import('@/pages/ManageAliasesPage.vue')
const ManageRecordsPage = async () => import('@/pages/ManageRecordsPage.vue')
const ManageExternalRepositoriesPage = async () => import('@/pages/ManageExternalRepositoriesPage.vue')
const ManageAffiliationsPage = async () => import('@/pages/ManageAffiliationsPage.vue')
const EditAffiliationPage = async () => import('@/pages/EditAffiliationPage.vue')

/**
 *
 * @param {import('vuex').Store} store The store to use
 * @param {import('axios').AxiosInstance} axios The axios instance to use
 */
export default function (store, axios) {
  const ProfileAPI = useProfileAPI(axios)
  const NavigationGuards = useNavigationGuards(axios)

  /** @type {Array<import('vue-router').Route>} */
  return [
    {
      path: 'account',
      name: 'AccountPage',
      redirect: { name: 'EditProfilePage' }
    },
    {
      path: 'account/profile',
      name: 'EditProfilePage',
      component: EditProfilePage,
      meta: {
        'porg-auth': { roles: ['user'] }
      },
      props: route => ({
        currentProfileData: route.meta.profileSettings
      }),
      async beforeEnter (to, from, next) {
        const settings = await ProfileAPI.getSettings()
        to.meta.profileSettings = settings
        next()
      }
    },
    {
      path: 'account/emails',
      name: 'ManageEmailsPage',
      component: ManageEmailsPage,
      meta: {
        'porg-auth': { roles: ['user'] }
      },
      props: route => ({
        settings: route.meta.settings,
        currentEmails: route.meta.emails
      }),
      async beforeEnter (to, from, next) {
        const emails = await ProfileAPI.getEmails()
        to.meta.emails = emails
        const settings = await ProfileAPI.getSettings()
        to.meta.settings = settings
        next()
      }
    },
    {
      path: 'account/aliases',
      name: 'ManageAliasesPage',
      component: ManageAliasesPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: (route) => ({
        aliases: route.meta.aliases
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          to.meta.aliases = await ProfileAPI.getAliases()
          return next()
        }
      )
    },
    {
      path: 'account/records',
      name: 'ManageRecordsPage',
      component: ManageRecordsPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        },
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      props: route => ({
        page: Number(route.query.page) || 1,
        q: route.query.q,
        perPage: Number(route.query.perPage) || undefined,
        type: underscore.intersection(recordTypes,
          typeof route.query.type === 'string' ? [route.query.type] : route.query.type)
      })
    },
    {
      path: 'account/records/:id',
      name: 'EditRecordPage',
      component: EditRecordPage,
      meta: {
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({ record: route.meta.record, mode: route.query.mode }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          // Pre-fetch record
          const request = { id: to.params.id }
          if (to.query.mode === 'resolve-inconsistency') {
            request.allowDraft = true
          }
          const record = await NavigationGuards.fetchRecord(request)
          // Check if user should have permission to edit this record
          const profile = store.state.profile

          if (profile.roles.includes('operator') ||
            isRecordAuthor({ record, userId: profile.id }) ||
            isUnitManagerOfAuthorMemberships({ record, profile })) {
            to.meta.record = record
            return next()
          }
          // Vue.prototype.$notification.send({ key: 'record.edition.guard.no-permission-to-edit', type: 'warn', dismiss: true })
          return next({ ...(from ?? { path: '/' }), replace: true })
        }
      )
    },
    {
      path: 'account/records/deduplication',
      name: 'EditRecordDeduplication',
      component: EditRecordPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: route => ({ record: route.params.record, mode: 'deduplication' })
    },
    {
      path: 'account/external-repositories',
      name: 'ManageExternalRepositoriesPage',
      component: ManageExternalRepositoriesPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: (route) => ({
        initialRepositories: route.meta.repositories
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          to.meta.repositories = await ProfileAPI.getExternalProfiles()
          return next()
        }
      )
    },
    {
      path: 'account/affiliations',
      name: 'ManageAffiliationsPage',
      component: ManageAffiliationsPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        },
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      props: route => ({
        page: Number(route.query.page) || 1,
        perPage: Number(route.query.perPage) || undefined
      })
    },
    {
      path: 'account/affiliations/:affiliationId',
      name: 'EditAffiliationPage',
      component: EditAffiliationPage,
      meta: {
        'porg-auth': {
          roles: ['author']
        }
      },
      props: route => ({
        unit: route.meta.unit,
        affiliation: route.meta.affiliation
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          const affiliation = await ProfileAPI.getAffiliation({ affiliationId: to.params.affiliationId })
          to.meta.unit = affiliation.unit
          delete affiliation.unit
          to.meta.affiliation = affiliation
          return next()
        }
      )
    }
  ]
}
