import useNavigationGuards, { guardWithErrorHandling, getUserRolesFromCookie } from '@/router/navigation-guards'
import useResearchersAPI from '@/api/researchers'

const ManageUnitInfoPage = async () => import('@/pages/ManageUnitInfoPage.vue')
const ManageUnitAffiliationsPage = async () => import('@/pages/ManageUnitAffiliationsPage.vue')
const ManageUnitAffiliationsTabPage = async () => import('@/pages/ManageUnitAffiliationsTabPage.vue')
const EditUnitAffiliationPage = async () => import('@/pages/EditUnitAffiliationPage.vue')

/**
 *
 * @param axios
 */
export default function (axios) {
  const NavigationGuards = useNavigationGuards(axios)
  const ResearchersAPI = useResearchersAPI(axios)
  return [
    {
      path: 'management/:unitId',
      name: 'ManageUnitPage',
      redirect: { name: 'ManageUnitInfoPage' },
      props: route => ({
        unitId: route.params.unitId
      }),
      beforeEnter: async (to, from, next) => {
        if (await useNavigationGuards.isUnitManager({ unitId: to.params.unitId })) {
          return next()
        }
        next({ name: 'PageNotFound', params: [to.path] })
      }
    },
    {
      path: 'management/:unitId/info',
      name: 'ManageUnitInfoPage',
      component: ManageUnitInfoPage,
      props: route => ({
        unitId: route.params.unitId,
        currentUnitData: route.meta.currentUnitData,
        currentManagersPage: route.meta.currentManagersPage
      }),
      beforeEnter: async (to, from, next) => {
        if (await NavigationGuards.isUnitManager({ unitId: to.params.unitId })) {
          const [unit, managersPage] = await Promise.all([
            NavigationGuards.fetchUnit({ id: to.params.unitId }),
            NavigationGuards.fetchAllUnitManagers({ id: to.params.unitId })
          ])
          to.meta.currentUnitData = unit
          to.meta.currentManagersPage = managersPage
          return next()
        }
      }
    },
    {
      path: 'management/:unitId/affiliations',
      name: 'ManageUnitAffiliationsPage',
      redirect: { name: 'ManageUnitCurrentAffiliationsPage' },
      component: ManageUnitAffiliationsPage,
      props: route => ({
        unit: route.meta.unit,
        page: Number(route.query.page) || 1,
        q: route.query.q,
        perPage: Number(route.query.perPage) || undefined,
        verified: route.meta.verified,
        ongoing: route.meta.ongoing
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          if (!await NavigationGuards.isUnitManager({ unitId: to.params.unitId })) {
            next({ name: 'PageNotFound', params: [to.path] })
          }
          const unit = await NavigationGuards.fetchUnit({ id: to.params.unitId })
          to.meta.unit = unit
          return next()
        }
      ),
      meta: {
        activeLinkStrategy: 'routeAndChildren'
      },
      children: [
        {
          path: 'pending',
          name: 'ManageUnitPendingAffiliationsPage',
          component: ManageUnitAffiliationsTabPage,
          meta: {
            verified: false,
            ongoing: undefined,
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'current',
          name: 'ManageUnitCurrentAffiliationsPage',
          component: ManageUnitAffiliationsTabPage,
          meta: {
            verified: true,
            ongoing: true,
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'past',
          name: 'ManageUnitPastAffiliationsPage',
          component: ManageUnitAffiliationsTabPage,
          meta: {
            verified: true,
            ongoing: false,
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        }
      ]
    },
    {
      path: 'management/:unitId/affiliations/:userId/:affiliationId',
      name: 'EditUnitAffiliationPage',
      component: EditUnitAffiliationPage,
      props: route => ({
        researcher: route.meta.researcher,
        unit: route.meta.unit,
        affiliation: route.meta.affiliation
      }),
      beforeEnter: guardWithErrorHandling(
        async (to, from, next) => {
          const roles = getUserRolesFromCookie()
          if (!await NavigationGuards.isUnitManager({ unitId: to.params.unitId }) && !roles.includes('operator')) {
            next({ name: 'PageNotFound', params: [to.path] })
          }
          const [researcher, affiliation] = await Promise.all([
            ResearchersAPI.get({ userId: to.params.userId }),
            ResearchersAPI.getAffiliation({ userId: to.params.userId, affiliationId: to.params.affiliationId })
          ])
          to.meta.researcher = researcher
          to.meta.unit = affiliation.unit
          delete affiliation.unit
          to.meta.affiliation = affiliation
          return next()
        }
      )
    }
  ]
}
