import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import PublicRoutes from '@/router/public'
import PrivateRoutes from '@/router/private'

import PageNotFoundPage from '@/pages/PageNotFoundPage.vue'
import { setLocale } from '@/i18n'
import { useHeadSafe } from '@unhead/vue'

/**
 * Initializes and returns the configured router with the provided store.
 *
 * The router will use:
 * - Memory-based history during server-side rendering (SSR)
 * - Browser-based web history for client-side rendered apps
 * @param {import('vuex').Store} store - The Redux or similar state management store to be used for private routes resolution
 * @param {import('axios').AxiosInstance} axios - An Axios instance for making HTTP requests to the API
 * @returns {import('vue-router').Router} A configured Router instance with the specified scroll behavior, history,
 * and routes, ready for navigation
 */
export function startRouter (store, axios) {
  const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
      // allows saving position when navigation happens between same route
      if (to.meta?.scrollBehavior?.routeUpdate === 'savedPosition' && to.path === from.path) {
        return savedPosition
      }
      // allows saving position when navigation happens between different routes
      if (to.meta?.scrollBehavior?.routeEnter === 'savedPosition' && to.path !== from.path) {
        return savedPosition
      }
      return { x: 0, y: 0 }
    },
    routes: PublicRoutes(axios).concat(PrivateRoutes(store, axios)),
    history: import.meta.env.SSR ? createMemoryHistory('/') : createWebHistory('/')
  })
  return router
}

/**
 * Sets up a Vue router with various navigation and store-related functionality, runs after vue-porg.
 * @param {object} app - The Vue application instance
 * @param {object} router - The Vue Router instance
 * @param {object} store - The Vuex store instance
 * @param {object} options - An object containing options for the router
 * @param {string} options.baseUrl - The base URL for the app
 * @param {string} options.lang - The language code for the app
 */
export function afterVuePorg (app, router, store, { baseUrl, lang }) {
  router.addRoute(
    {
      path: '/:pathMatch(.*)*',
      name: 'PageNotFound',
      component: PageNotFoundPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      }
    }
  )
  router.beforeEach(async function (to, from, next) {
    // Remove old hreflang links
    if (!import.meta.env.SSR) {
      Array.prototype.slice.call(document.getElementsByTagName('link')).forEach(link => {
        if (link.hreflang) {
          link.remove()
        }
      })
    }

    // Add new hreflang links
    useHeadSafe({
      title: 'Scholar',
      htmlAttrs: { lang, amp: true },
      link: [
        {
          hreflang: 'en',
          rel: 'alternate',
          href: `${baseUrl}${to.path}?lang=en`
        },
        {
          hreflang: 'pt',
          rel: 'alternate',
          href: `${baseUrl}${to.path}?lang=pt`
        }
      ]
    })

    if (to.query.lang) {
      setLocale(to.query.lang)
    }

    if (to.path === '/error-page') {
      return next()
    }
    await store.dispatch('setProgressBar', 10)
    // we want this here because we want vue-porg's permission system to run before this global navigation guard
    if (from.name && to.path === from.path) {
      // don't run when only the query params change
      return next()
    }

    if (app.config.globalProperties.$getInfoFromCookie()) {
      await store.dispatch('fetchProfile')
      await store.dispatch('setProgressBar', 60)
    }
    next()
  })

  router.afterEach(async (to, from, failure) => {
    if (failure) console.log(failure)

    if (!from.name || (to.fullPath !== from.fullPath)) {
      await store.dispatch('completeProgressBar')
    }
  })
}
