<template>
  <div>
    <div v-if="files.length > 0">
      <h2 class="h5--ssp">
        <strong>{{ t('title') }}</strong>
      </h2>
      <ul class="list-downloads">
        <li
          v-for="file in files"
          :key="file.name"
          class="download-file"
        >
          <span class="download-file__row">
            <span class="download-file__link">
              <component
                :is="file.id ? 'a' : 'span'"
                :href="`${baseURL}/api/records/${recordId ? recordId : null}/file/${file.id}`"
                target="_blank"
                :class="{'u-text-blue': file.id, 'u-text-slate': !file.id}"
                :name="file.name"
              >
                {{ file.name }}
              </component>
            </span>
            <span class="u-text-slate">
              ({{ prettyBytes(file.size) }})
            </span>
          </span>

          <div class="download-file__badge">
            <span
              class="label label--sm"
              :class="{
                'label--green': getFileRights(file) === 'open-access',
                'label--orange': getFileRights(file) === 'restricted-access',
                'label--red':['metadata-only-access', 'embargoed-access'].includes(getFileRights(file))
              }"
            >
              {{ t(`permission.${getFileRights(file)}.name`) }}
            </span>
          </div>
          <br>
          <span
            v-if="!file.id"
            class="download-file__description u-text-slate"
          >{{ t(`permission.${getFileRights(file)}.text`, {date: dateString}) }}</span>
        </li>

        <a
          v-if="files.some(file => !file.id)"
          :href="encodeURI(`mailto:bist@tecnico.ulisboa.pt?subject=${t('email.subject')}&body=${t('email.body')}`
            + `\n\n${currentURL}`)"
          class="u-text-blue-600"
        >
          {{ t('askCopy') }}
        </a>
      </ul>
    </div>
    <div v-else>
      <h2 class="h5--ssp">
        {{ t('files.part.files') }}
      </h2>
      <p>{{ t(`emptyText`) }}</p>

      <!-- TODO: This never appears because the mail is never passed to the component -->
      <a
        v-if="email"
        :href="`mailto:${email}`"
        class="u-text-blue-600"
      >
        {{ t('askCopy') }}
      </a>
    </div>
  </div>
</template>

<script>
import DateHelpers from '@/mixins/DateHelpers'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

export default {
  mixins: [DateHelpers],
  inject: ['$baseUrl'],
  props: {
    recordId: {
      type: String,
      default: null
    },
    files: {
      type: Array,
      default: () => []
    },
    rights: {
      type: String,
      default: 'metadata-only-access'
    },
    dateAvailable: {
      type: Object,
      default: null
    },
    email: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        en: {
          title: 'Download files',
          permission: {
            'embargoed-access': {
              text: 'The file is embargoed until {date}. You can ask for a copy directly from the authors.',
              name: 'Embargoed access'
            },
            'metadata-only-access': {
              text: 'The file is not available for download. You can ask for a copy directly from the authors.',
              name: 'Closed access'
            },
            'open-access': {
              name: 'Available'
            },
            'restricted-access': {
              text: 'The file is only available for authenticated users.',
              name: 'Restricted access'
            }
          },
          emptyText: 'The author did not upload files to the system.',
          askCopy: 'Ask for a copy of the files',
          email: {
            subject: 'Request for scholar document',
            body: 'I hereby request access to the document related to the publication that is in this link:'
          }
        },
        pt: {
          title: 'Download de ficheiros',
          permission: {
            'embargoed-access': {
              text: 'O ficheiro está embargado até {date}. Pode requerer uma cópia diretamente aos autores.',
              name: 'Acesso embargado'
            },
            'metadata-only-access': {
              text: 'O ficheiro não está disponível para download. Pode requerer uma cópia diretamente aos autores.',
              name: 'Acesso fechado'
            },
            'open-access': {
              name: 'Acesso aberto'
            },
            'restricted-access': {
              text: 'O ficheiro só está disponível para utilizadores autenticados na plataforma.',
              name: 'Acesso restrito'
            }
          },
          emptyText: 'Não existe registo de ficheiros associados a esta publicação neste repositório. Pode requerer uma cópia diretamente aos autores.',
          askCopy: 'Pedir cópia dos ficheiros',
          email: {
            subject: 'Pedido de acesso a documento scholar',
            body: 'Venho por este meio requerer acesso ao documento referente á publicação que se encontra neste link:'
          }
        }
      }
    })

    let currentURL
    onMounted(() => {
      currentURL = window.location.href
    })
    return { t, currentURL }
  },
  data () {
    return { baseURL: this.$baseUrl }
  },
  computed: {
    dateString () {
      if (this.dateAvailable) {
        return this.generateDateString(this.dateAvailable)
      } else {
        return ''
      }
    }
  },
  methods: {
    getFileType (name) {
      const parts = name.split('.')
      return parts[parts.length - 1]
    },
    getFileRights (file) {
      const rights = file.rights ? file.rights : this.rights
      if (this.dateAvailable) {
        const dateAvailable = dayjs(`${this.dateAvailable.year}-${this.dateAvailable.month}-${this.dateAvailable.day}`)
        if (rights === 'embargoed-access' && dateAvailable.isBefore(dayjs())) return 'open-access'
      }
      return rights
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/variables" as *;

.download-file + .download-file {
  margin-top: 1rem;
}
.download-file {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1;
}
.download-file__description {
  display: inline-block;
  margin-top: 0.5rem;
  font-family: $primary-font;
  font-weight: 400;
}

.download-file__link {
  display: inline-block;
  font-weight: 600;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-right: 0.25em;
}
.download-file__row {
  display: inline-flex;
  align-items: center;
}
.download-file__badge {
  margin-top: 0.5rem;
}
</style>
