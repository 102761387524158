import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'

const RecordEditionsPage = async () => import('@/pages/RecordEditionsPage.vue')
const RecordEditionPage = async () => import('@/pages/RecordEditionPage.vue')

/**
 *
 * @param axios
 */
export default function (axios) {
  const NavigationGuards = useNavigationGuards(axios)
  return [
    {
      path: '/records/:id/edits',
      name: 'RecordEditionsPage',
      component: RecordEditionsPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({ record: route.meta.record }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          to.meta.record = await NavigationGuards.fetchRecord({ id: to.params.id, sources: true, countView: true, mergeHistory: true })
          for (const source of to.meta.record.metadata.sources) {
            source.author = await NavigationGuards.fetchAuthorByUserId({ userId: source.author, countView: false })
          }
          for (const merge of to.meta.record.metadata.mergeHistory) {
            merge.metadata.preferredSource.author = await NavigationGuards.fetchAuthorByUserId({ userId: merge.metadata.preferredSource.author, countView: false })
          }
          next()
        }
      )
    },
    {
      path: '/records/:id/edits/:editId',
      name: 'RecordEditionPage',
      component: RecordEditionPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout',
        'porg-auth': {
          roles: ['user']
        }
      },
      props: route => ({
        source: route.meta.source,
        previousVersion: route.meta.previousVersion,
        nextVersion: route.meta.nextVersion
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          const record = await NavigationGuards.fetchRecord({ id: to.params.id, sources: true, countView: true })
          const sources = record.metadata.sources.sort((a, b) => {
            if (new Date(a.updated) > new Date(b.updated)) {
              return 1
            } else {
              return -1
            }
          })
          const sourceIndex = sources.findIndex(({ id }) => id === to.params.editId)
          const source = sources[sourceIndex]
          source.author = await NavigationGuards.fetchAuthorByUserId({ userId: source.author, countView: false })
          source.record.metadata = record.metadata
          to.meta.previousVersion = sources[sourceIndex - 1] || null
          to.meta.nextVersion = sources[sourceIndex + 1] || null
          to.meta.source = source
          next()
        }
      )
    }
  ]
}
