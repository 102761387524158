import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useUsersAPI from '@/api/users'
import useUnitsAPI from '@/api/units'

const AdministrateUnitsPage = async () => import('@/pages/AdministrateUnitsPage.vue')
const AdministrateUnitPage = async () => import('@/pages/AdministrateUnitPage.vue')
const AdministrateUsersPage = async () => import('@/pages/AdministrateUsersPage.vue')
const AdministrateUsersTabPage = async () => import('@/pages/AdministrateUsersTabPage.vue')
const AdministrateUserPage = async () => import('@/pages/AdministrateUserPage.vue')

/**
 *
 * @param {import('axios').AxiosInstance} axios An instance of axios to make requests to the API
 */
export default function (axios) {
  const UsersAPI = useUsersAPI(axios)
  const UnitsAPI = useUnitsAPI(axios)
  const NavigationGuards = useNavigationGuards(axios)

  return [
    {
      path: 'admin/units',
      name: 'AdministrateUnitsPage',
      component: AdministrateUnitsPage,
      meta: {
        'porg-auth': {
          roles: ['scholar-admin']
        },
        scrollBehavior: {
          routeUpdate: 'savedPosition'
        }
      },
      props: route => ({
        page: Number(route.query.page) || 1,
        q: route.query.q,
        perPage: Number(route.query.perPage) || undefined
      })
    },
    {
      path: 'admin/units/:unitId',
      name: 'AdministrateUnitPage',
      component: AdministrateUnitPage,
      meta: {
        'porg-auth': {
          roles: ['scholar-admin']
        }
      },
      props: route => ({
        unitId: route.params.unitId,
        unitTypes: route.meta.unitTypes,
        currentUnitData: route.meta.currentUnitData,
        currentManagersPage: route.meta.currentManagersPage
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          const [unitTypes, unit, managersPage] = await Promise.all([
            NavigationGuards.fetchUnitTypes(),
            NavigationGuards.fetchUnit({ id: to.params.unitId }),
            NavigationGuards.fetchAllUnitManagers({ id: to.params.unitId })
          ])
          to.meta.unitTypes = unitTypes
          to.meta.currentUnitData = unit
          to.meta.currentManagersPage = managersPage
          next()
        }
      )
    },
    {
      path: 'admin/users',
      name: 'AdministrateUsersPage',
      redirect: { name: 'AdministrateUsersUserRolePage' },
      component: AdministrateUsersPage,
      meta: {
        'porg-auth': {
          roles: ['scholar-admin']
        },
        activeLinkStrategy: 'routeAndChildren'
      },
      props: route => ({
        page: Number(route.query.page) || 1,
        q: route.query.q,
        perPage: Number(route.query.perPage) || undefined,
        roles: route.meta.roles
      }),
      children: [
        {
          path: 'all',
          name: 'AdministrateUsersUserRolePage',
          component: AdministrateUsersTabPage,
          meta: {
            roles: ['user'],
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'authors',
          name: 'AdministrateUsersAuthorRolePage',
          component: AdministrateUsersTabPage,
          meta: {
            roles: ['author', 'pre-setup-author'],
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'operators',
          name: 'AdministrateUsersOperatorRolePage',
          component: AdministrateUsersTabPage,
          meta: {
            roles: ['operator'],
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        },
        {
          path: 'administrators',
          name: 'AdministrateUsersAdminRolePage',
          component: AdministrateUsersTabPage,
          meta: {
            roles: ['scholar-admin'],
            scrollBehavior: {
              routeUpdate: 'savedPosition'
            }
          }
        }
      ]
    },
    {
      path: 'admin/users/:userId',
      name: 'AdministrateUserPage',
      component: AdministrateUserPage,
      meta: {
        'porg-auth': {
          roles: ['scholar-admin']
        }
      },
      props: route => ({
        originalUser: route.meta.user,
        units: route.meta.units || []
      }),
      beforeEnter: guardWithErrorHandling(
        async function (to, from, next) {
          to.meta.user = await UsersAPI.get({ id: to.params.userId })
          if (to.meta.user.units.length > 0) {
            const units = []
            for (const unitID of to.meta.user.units) {
              const unit = await UnitsAPI.get({ unitID })
              units.push(unit)
            }
            to.meta.units = units
          }
          next()
        }
      )
    }
  ]
}
