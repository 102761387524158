// import Vue from 'vue'
// import translationsPt from '@/i18n/translations-pt.json'
import { createI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import axios from 'axios'
import useProfileAPI from '@/api/profile'
import configuration from '@/api/configuration'

let loadedLanguages
let customTranslations = configuration(null).getTranslations()
let i18n

/**
 * Sets the requested language, if the translations aren't yet loaded it loads them
 * @param {string} lang Language to set (eg. 'pt', 'en')
 * @param {object} auth Authentication data provided by the auth mixin (this.$auth)
 * @param {import('axios').AxiosInstance} axios Instance of axios
 */
export async function setLocale (lang, auth, axios) {
  if (customTranslations instanceof Promise) {
    customTranslations = await customTranslations
  }

  for (const locale in customTranslations) {
    i18n.global.mergeLocaleMessage(locale, customTranslations[locale])
  }

  // If the same language
  if (i18n.global.locale.value === lang) {
    return setI18nLanguage(lang)
  }

  // Update lang in profile
  if (auth?.isLogged()) {
    const ProfileAPI = useProfileAPI(axios)
    await ProfileAPI.update({ locale: lang })
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }

  // If the language hasn't been loaded yet
  const messages = await import(`./translations-${lang}.json`)
  i18n.global.setLocaleMessage(lang, messages.default)
  i18n.global.mergeLocaleMessage(lang, customTranslations[lang])
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}

/**
 * Does all required work to set the language, function does not handle translations async load
 * @param {string} lang Language to set (eg. 'pt', 'en')
 */
function setI18nLanguage (lang) {
  i18n.global.locale.value = lang

  dayjs.locale(lang)
  if (!import.meta.env.SSR) {
    window.localStorage.setItem('language', lang)
    document.querySelector('html').setAttribute('lang', lang)
  }
  axios.defaults.headers.common['Accept-Language'] = lang
  return lang
}

/**
 *
 * @param {string} lang - Language to set (eg. 'pt', 'en')
 */
export function startI18n (lang = 'pt') {
  loadedLanguages = []
  i18n = createI18n({
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackWarn: false,
    fallbackRoot: true,
    legacy: false
  })
  // Set starting language
  let language
  if (!import.meta.env.SSR) {
    language = window.localStorage.getItem('language') || window.navigator.language.split('-')[0] || lang
  } else {
    language = lang
  }
  language = language === 'pt' ? 'pt' : 'en'
  setLocale(language)
  return i18n
}

/**
 *
 */
export function getI18n () {
  return i18n
}
