<template>
  <Component
    :is="layout"
    :record="record"
  >
    <template #sideCard>
      <slot name="sideCard" />
    </template>
    <template #header>
      <HeaderPart
        :record="record"
        class="card"
      >
        <h2
          v-if="record.titleAlternative"
          class="publication__alternative-title h4"
        >
          {{ record.titleAlternative.trim() }}
        </h2>
      </HeaderPart>
    </template>
    <template #keyInformation>
      <users-part
        v-model="record.creators"
        field-name="authors"
      />
      <string-part
        field-name="dateIssued"
        :model-value="generateDateString(record.dateIssued)"
      />
      <text-part
        :model-value="record.description"
        field-name="description"
      />
    </template>
    <template #publicationDetails>
      <InstitutionUsersPart
        :users="record.creators"
        :section-title="$t('record.authors.institution', {communityName: configuration.institutionShortName })"
      />
      <enum-part
        :model-value="record.version"
        field-name="version"
      />
      <string-part
        field-name="publisher"
        :model-value="record.publisher"
      />
      <string-part
        field-name="govdoc"
        :model-value="record.govdoc"
      />
      <string-part
        field-name="pmid"
        :model-value="pmid"
      />
      <string-part
        field-name="doi"
        :model-value="record.doi"
      />
      <string-part
        field-name="wos"
        :model-value="wos"
      />
      <SubjectPart
        v-if="record.subject"
        :subject="record.subject"
      />
      <enum-part
        :model-value="record.language"
        field-name="languageIso"
      />
      <string-part
        field-name="identifierUri"
        :model-value="record.identifierUri"
      />
      <RightsPart
        :type="record.rights"
        :date-available="record.dateAvailable"
        :embargofct="record.embargofct"
      />
      <enum-part
        :model-value="record.licenseCondition"
        field-name="license"
      />
      <funding-references-part :funding-references="record.fundingReferences" />
    </template>
  </Component>
</template>

<script>
import SingleRecordLayout from '@/layouts/SingleRecordLayout.vue'
import PreviewRecordLayout from '@/layouts/PreviewRecordLayout.vue'
import HeaderPart from '@/components/records/views/parts/HeaderPart.vue'
import UsersPart from '@/components/records/views/parts/UsersPart.vue'
import InstitutionUsersPart from '@/components/records/views/parts/InstitutionUsersPart.vue'
import RightsPart from '@/components/records/views/parts/RightsPart.vue'
import SubjectPart from '@/components/records/views/parts/SubjectPart.vue'
import StringPart from '@/components/records/views/parts/StringPart.vue'
import TextPart from '@/components/records/views/parts/TextPart.vue'
import EnumPart from '@/components/records/views/parts/EnumPart.vue'
import FundingReferencesPart from '@/components/records/views/parts/FundingReferencesPart.vue'

import DateHelpers from '@/mixins/DateHelpers'
import { mapState } from 'vuex'

export default {
  name: 'Report',
  components: {
    SingleRecordLayout,
    PreviewRecordLayout,
    HeaderPart,
    UsersPart,
    InstitutionUsersPart,
    TextPart,
    EnumPart,
    RightsPart,
    SubjectPart,
    StringPart,
    FundingReferencesPart
  },
  mixins: [DateHelpers],
  props: {
    record: {
      required: true,
      type: Object
    },
    layout: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({ configuration: state => state.configuration }),
    pmid () {
      return this.record.pmid ? String(this.record.pmid) : null
    },
    wos () {
      return this.record.wos ? String(this.record.wos) : null
    }
  }
}
</script>
