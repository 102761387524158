<template>
  <div class="container layout-sidebar">
    <div class="layout-sidebar-two-columns-container">
      <div class="layout-sidebar__sidebar">
        <div class="card">
          <section class="card__section card-row author-card__header">
            <UserAvatar
              :src="author.avatar"
              :name="author.name"
              class="card-row__figure"
            />
            <div class="card-row__text">
              <h1 class="author__name h4--ssp">
                {{ author.name }}
              </h1>
              <p class="ellipsize">
                {{ author.username }}
              </p>
              <p
                v-if="author.email"
                class="author__link--desktop ellipsize"
              >
                <a :href="'mailto:' + author.email">
                  {{ author.email }}
                </a>
              </p>
              <p
                v-if="author.website"
                class="author__website--desktop author__link--desktop break-all"
              >
                <a
                  :href="author.website"
                  rel="noreferrer"
                  target="_blank"
                >
                  {{ displayWebsite }}
                </a>
              </p>
              <div
                v-if="author.email || author.website"
                class="author__links--mobile"
              >
                <span
                  v-if="author.email"
                  class="author__link--mobile"
                >
                  <a :href="'mailto:' + author.email">
                    {{ t('researcher.public.profile.intro.card.email') }}
                  </a>
                </span>

                <span
                  v-if="author.website"
                  class="author__link--mobile"
                >
                  <a
                    :href="author.website"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {{ t('researcher.public.profile.intro.card.website') }}
                  </a>
                </span>
              </div>
            </div>
            <div class="card-row__meta">
              <button
                :aria-label="t(`researcher.public.profile.intro.card.toggle.aria-label.${isCardExpanded ? 'hide' : 'expand'}`)"
                class="mobile-expandable-card-section-toggle-trigger"
                :class="{ 'mobile-expandable-card-section-toggle-trigger--open': isCardExpanded }"
                @click.prevent="isCardExpanded = !isCardExpanded"
              />
            </div>
          </section>
          <section
            v-if="author.bio && author.bio.short"
            class="card__section mobile-expandable-card-section"
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
          >
            <p class="small author__bio">
              {{ author.bio.short }}
            </p>
          </section>
          <section
            v-if="author.interests && author.interests.length > 0"
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
          >
            <h1 class="card__section-title h5--ssp">
              {{ t('researcher.public.profile.intro.card.research.interests') }}
            </h1>
            <ul class="l-list l-list--inline">
              <li
                v-for="interest in author.interests"
                :key="interest"
                class="l-list__item"
              >
                <RouterLink
                  :to="{ name: 'ScientificInterestsPage', query: {q: interest} }"
                  class="label label--light label--outline label--lg"
                >
                  {{ interest }}
                </RouterLink>
              </li>
            </ul>
          </section>
          <section
            v-if="author.fos && author.fos.primary"
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
          >
            <h1 class="card__section-title h5--ssp">
              {{ t('researcher.public.profile.intro.card.main.field.of.science') }}
            </h1>
            <p>{{ t(`fields.record.subjectFos.values.${author.fos.primary}`) }}</p>
          </section>
          <section
            v-if="author.externalProfiles && Object.keys(author.externalProfiles).length > 0"
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
          >
            <h1 class="card__section-title h5--ssp">
              {{ t('researcher.public.profile.intro.card.external.profiles') }}
            </h1>
            <dl>
              <div
                v-for="(profile, key) in author.externalProfiles"
                :key="key"
                class="author__external-profiles-item"
              >
                <dt>
                  <a
                    :href="profile.url"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {{ profile.name }}
                  </a>
                </dt>
                <dd class="author__external-profile-id">
                  <a
                    :href="profile.url"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {{ profile.id }}
                  </a>
                </dd>
              </div>
            </dl>
          </section>
        </div>
        <div class="card">
          <section
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
            style="min-height: 258px;"
          >
            <h1 class="card__section-title h5--ssp">
              {{ t('researcher.public.profile.intro.card.scientific.production') }}
            </h1>
            <ScientificProductionChart
              :data="scientificProduction"
            />
          </section>
        </div>
      </div>
      <main class="layout-sidebar__main">
        <WarningBox
          v-if="profile?.id === author.id && (!author.fos?.primary || !author.interests)"
          type="info"
          style="margin-bottom: 1rem;"
        >
          <template #badge>
            {{ t('profileInfo.badge') }}
          </template>
          <template #message>
            <p>{{ t('profileInfo.message.p1') }}<br>{{ t('profileInfo.message.p2') }}<br>{{ t('profileInfo.message.p3') }}</p>
            <p>
              <RouterLink
                class="u-text-underline"
                :to="{ name: 'AccountPage' }"
              >
                <strong>{{ t('profileInfo.action') }}</strong>
              </RouterLink>
            </p>
          </template>
        </WarningBox>

        <div class="tab-navigation tab-navigation--overflow">
          <ul class="tab-navigation__list">
            <li class="tab-navigation__list-item">
              <RouterLink
                :to="{ name: 'ResearcherRecordsPage', params: { userId: author.username } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <div class="tab-navigation__link">
                  {{ t('researcher.public.profile.tab.records') }}
                </div>
              </RouterLink>
            </li>
            <li
              v-if="author.isAdvisor"
              class="tab-navigation__list-item"
            >
              <RouterLink
                :to="{ name: 'ResearcherAdvisedRecordsPage', params: { userId: author.username } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ t('researcher.public.profile.tab.advised-records') }}
                </span>
              </RouterLink>
            </li>
            <li class="tab-navigation__list-item">
              <RouterLink
                :to="{ name: 'ResearcherAffiliationsPage', params: { userId: author.username } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ t('researcher.public.profile.tab.affiliations') }}
                </span>
              </RouterLink>
            </li>
            <li class="tab-navigation__list-item">
              <RouterLink
                :to="{ name: 'ResearcherCoAuthorsPage', params: { userId: author.username } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ t('researcher.public.profile.tab.co-authors') }}
                </span>
              </RouterLink>
            </li>
            <li class="tab-navigation__list-item">
              <RouterLink
                v-if="author.bio && author.bio.extended"
                :to="{ name: 'ResearcherBioPage', params: { userId: author.username } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ t('researcher.public.profile.tab.bio') }}
                </span>
              </RouterLink>
            </li>
          </ul>
        </div>
        <RouterView
          :user-id="author.id"
          :author="author"
        />
      </main>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import WarningBox from '@/components/utils/WarningBox.vue'
import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useResearcherAPI from '@/api/researchers'
import { mapState } from 'vuex'
import ScientificProductionChart from '@/components/charts/ScientificProduction.vue'
import { useI18n } from 'vue-i18n'
import { useHeadSafe } from '@unhead/vue'

export default {
  components: {
    UserAvatar,
    ScientificProductionChart,
    WarningBox
  },
  inject: ['$axios'],
  beforeRouteUpdate: guardWithErrorHandling(
    async function (to, from, next) {
      if (to.params.userId === this.author.id) {
        to.meta.author = this.author
        to.meta.scientificProduction = this.scientificProduction
        return next()
      }
      const [author, scientificProduction] = await Promise.all([
        useNavigationGuards(this.$axios).fetchAuthorByUserId({ userId: to.params.userId }),
        this.ResearchersAPI.getScientificProduction({ userId: to.params.userId })
      ])
      to.meta.author = author
      to.meta.scientificProduction = scientificProduction
      next()
    }
  ),
  props: {
    author: {
      type: Object,
      required: true
    },
    scientificProduction: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          profileInfo: {
            badge: 'Recomendação',
            message: {
              p1: 'Há informações em falta no seu perfil.',
              p2: 'Por favor edite o seu perfil, adicione a sua área científica (campo FOS), os seus interesses científicos e as suas afiliações com unidades, caso se aplique.',
              p3: 'O preenchimento do perfil torna a sua página mais visível.'
            },
            action: 'Editar perfil'
          }
        },
        en: {
          profileInfo: {
            badge: 'Recommendation',
            message: {
              p1: 'There are missing information in your profile.',
              p2: 'Please edit your profile and add a filed of science (FOS), a list of scientific interests and a list of affiliations if you have or had any.',
              p3: 'The filling of the profile makes the page more visible.'
            },
            action: 'Edit profile'
          }
        }
      }
    })
    const metaInfo = {
      titleTemplate: `%s – ${props.author.name} – Scholar`,
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Person',
          name: props.author.name,
          image: props.author.avatar
        }
      }]
    }
    useHeadSafe(metaInfo)
    return { t }
  },
  data () {
    return {
      ResearchersAPI: useResearcherAPI(this.$axios),
      isCardExpanded: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    displayWebsite () {
      if (!this.author.website) return undefined
      const regex = /^https?:\/\/(?<name>.+)$/
      return this.author.website.match(regex)?.groups?.name ?? this.author.website
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;

.author-card__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: flex-start;

  @media screen and (width <= 60rem) {
    .author__links--mobile {
      display: block;
    }
    .author__link--desktop {
      display: none;
    }
    .card-row__figure {
      // extends figure--56
      min-width: 3.5rem;
      width: 3.5rem;
      min-height: 3.5rem;
      height: 3.5rem;
    }
  }

  @media screen and (width >= 60rem) {
    flex-flow: column nowrap;
    text-align: center;

    .author__links--mobile {
      display: none;
    }
    .author__link--desktop {
      display: block;
    }
    .card-row__figure {
      margin: 0 auto 1rem;

      // extends figure--80
      min-width: 5rem;
      width: 5rem;
      min-height: 5rem;
      height: 5rem;
    }
  }

  .card-row__text {
    max-width: 100%;
    overflow: hidden;

    .ellipsize {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .break-all {
      word-break: break-all;
    }
  }

  .card-row__meta {
    align-self: baseline;
  }
}
.author__links--mobile {
  margin-top: 0.5rem;
}
.author__link--mobile + .author__link--mobile {
  margin-left: 0.75rem;
}
.author__link--mobile a,
.author__website--desktop a {
  color: $blue;
  font-weight: 600;
}

.author__name {
  font-family: $title-font;
  font-weight: 500;
  margin: 0;

  @media screen and (width >= 60rem) {
    // extends h3
    font-size: 1.4375rem;
    color: $dark;
    font-weight: 500;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 4px;
  }
}

.author__bio {
  white-space: pre-line;
  text-align: start;
}

.author__external-profile-id {
  color: $gray-400;
}

.author__external-profiles-item {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
