<template>
  <div>
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('researcher.public.profile.co-authors.header') }}
        </h1>
        <p>{{ $t('researcher.public.profile.co-authors.description') }}</p>
      </div>
    </header>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :query="searchQuery"
      :page-data="coAuthors"
    >
      <template #search-bar="{buttonAttrs, inputAttrs}">
        <div class="f-field-search">
          <button
            class="f-field-search__submit"
            :aria-label="$t('search')"
            v-bind="buttonAttrs"
          />
          <input
            v-model.trim="searchQuery"
            :placeholder="$t('search.co-authors')"
            type="search"
            class="f-field-search__input f-field-search__input--card"
            v-bind="inputAttrs"
          >
        </div>
      </template>
      <template #list-skeleton>
        <UserSkeleton
          :count="6"
          class="card-list"
        />
      </template>
      <template #list>
        <ul class="card card-list">
          <li
            v-for="coAuthor in coAuthors.items"
            :key="coAuthor.author.userId"
            class="card-row"
          >
            <UserAvatar
              :src="coAuthor.author.avatar"
              class="card-row__figure"
              name="user.name"
            />
            <div class="card-row__text">
              <h1 class="card-row__title h5--ssp">
                <RouterLink
                  :to="{ name: 'ResearcherPage', params: { userId: coAuthor.author.username } }"
                  class="card-row__link"
                >
                  {{ coAuthor.author.name }}
                </RouterLink>
              </h1>
              <p>{{ coAuthor.author.username }}</p>
              <collapsible-list>
                <template #trigger-text>
                  {{ $t('researcher.public.profile.co-authors.collaborations.count', coAuthor.records.length, { count: coAuthor.records.length }) }}
                </template>
                <template #list>
                  <div class="co-authors-page__records">
                    <div
                      v-for="record in coAuthor.records"
                      :key="record.metadata.id"
                      class="co-authors-page__record"
                    >
                      <p class="small">
                        {{ $t(`record.view.types.${record.type}`) }}
                      </p>
                      <router-link
                        :to="{ name: 'RecordPage', params: { id: record.metadata.id } }"
                        class="card-row__title h5--ssp"
                      >
                        {{ record.title }}
                      </router-link>
                    </div>
                  </div>
                </template>
              </collapsible-list>
            </div>
          </li>
        </ul>
      </template>
      <template #pagination>
        <PaginationBar
          :current-page="currentPage"
          :total-items="coAuthors.totalItems"
          :items-per-page="perPage"
        />
      </template>
      <template #empty-state="{ isAlwaysEmpty }">
        <PersonEmptyState>
          <template #title>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.co-authors.empty.state.always.empty.header') }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.co-authors.empty.state.search.results.header', { query: emptyStateSearchQuery }) }}
            </template>
          </template>
          <template #description>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.co-authors.empty.state.always.empty.description') }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.co-authors.empty.state.search.results.description') }}
            </template>
          </template>
        </PersonEmptyState>
      </template>
    </SearchPaginatedList>
  </div>
</template>
<script>
import PaginationBar from '@/components/utils/PaginationBar.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import UserAvatar from '@/components/utils/UserAvatar'
import PersonEmptyState from '@/components/empty-states/Person.vue'
import UserSkeleton from '@/components/loading-skeletons/User.vue'
import useResearcherAPI from '@/api/researchers'
import CollapsibleList from '@/components/utils/CollapsibleList'
import { useI18n } from 'vue-i18n'
import { useHeadSafe } from '@unhead/vue'

export default {
  components: {
    PaginationBar,
    SearchPaginatedList,
    UserAvatar,
    PersonEmptyState,
    UserSkeleton,
    CollapsibleList
  },
  inject: ['$axios'],
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.emptyStateSearchQuery = to.query.q

      this.currentPage = Number(to.query.page) || 1
      this.coAuthors = await this.ResearchersAPI.getCoAuthors({ userId: this.userId, page: this.currentPage, perPage: this.perPage, q: to.query.q })
      this.isSearching = false
    }, 500)
    if (!import.meta.env.SSR && to.query.perPage) localStorage.setItem('perPage', Number(to.query.perPage) || 10)

    next()
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    q: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: import.meta.env.SSR ? 10 : Number(localStorage.getItem('perPage')) || 10
    }
  },
  setup () {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          title: 'Coautores',
          description: 'Uma lista com todos os coautores'
        },
        en: {
          title: 'Coauthors',
          description: 'A list with all coauthors'
        }
      }
    })
    const metaInfo = {
      title: t('title'),
      meta: [{ name: 'description', content: t('description') }]
    }
    useHeadSafe(metaInfo)
    return { t }
  },
  data () {
    return {
      ResearchersAPI: useResearcherAPI(this.$axios),
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      coAuthors: undefined,
      searchQuery: this.q,
      searchTimeout: undefined
    }
  },
  watch: {
    async searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return

      const query = { ...this.$route.query, q: newValue }
      if (newValue === '') {
        delete query.q
      }
      delete query.page
      await this.$router.replace({ query })
    }
  },
  beforeUnmount () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.coAuthors = await this.ResearchersAPI.getCoAuthors({ userId: this.userId, page: this.currentPage, perPage: this.perPage, q: this.q })
    this.isInitialLoading = false
    // purposefully makes search query non-reactive to display on empty state
    this.emptyStateSearchQuery = this.searchQuery
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;

.co-authors-page__records {
  margin: 1rem;
  padding-left: 1rem;
  border-width: 0 0 0 1px;
  border-color: $light-gray;
  border-style: solid;
}
.co-authors-page__record {
  margin-bottom: 1rem;
}
</style>
