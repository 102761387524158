<template>
  <div>
    <component
      :is="components[layout]"
      v-if="layout"
    >
      <Suspense>
        <router-view />
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </component>

    <router-view v-else />
  </div>
</template>

<script setup>
import PageWithNavBarAndFooterLayout from '@/layouts/PageWithNavBarAndFooterLayout.vue'
import PageWithNavBarLayout from '@/layouts/PageWithNavBarLayout.vue'
import PreviewRecordLayout from '@/layouts/PreviewRecordLayout.vue'
import SingleRecordLayout from '@/layouts/SingleRecordLayout.vue'
import useProfileAPI from '@/api/profile'
import useConfigurationAPI from '@/api/configuration'
import { computed, inject, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const components = {
  PageWithNavBarAndFooterLayout,
  PageWithNavBarLayout,
  PreviewRecordLayout,
  SingleRecordLayout
}

// Initialize layout
const route = useRoute()
const layout = computed((t) => {
  const metas = route.matched.filter(r => r.meta.layout).map(r => r.meta.layout)
  const newLayout = metas.length > 0 ? metas.at(-1) : undefined
  return newLayout
})

const axios = inject('$axios')
const auth = inject('$auth')
// const notification = inject('$notification')

const router = useRouter()
const store = useStore()

const ProfileAPI = useProfileAPI(axios)
const ConfigurationAPI = useConfigurationAPI(axios)
// onMounted(() => {
//   document.getElementById('body-wrapper').style.display = 'block'
// })

// Load custom translations, instance configurations and profile in parallel, and in a non blocking way
Promise.all([ConfigurationAPI.getStaticConfig(), ConfigurationAPI.get()]).then(([staticConfig, config]) => {
  store.dispatch('setConfigurationState', { configuration: { ...config, ...staticConfig } })
})

onBeforeMount(async () => {
  let profilePromise

  if (auth?.isLogged()) {
    profilePromise = ProfileAPI.get()
    // Verifies if user has emails pending validation
    // this.$route returns '/' as the path inside this as such the window.location was used to retrieve the real location
    if (window.location.pathname !== '/email-verification') {
      const emails = await ProfileAPI.getEmails()
      if (emails.some(item => !item.verified)) {
        // notification.send({ key: 'verify.email.warning', type: 'warn', dismiss: 'true' })
      }
    }
  }

  // Await and process server responses
  try {
    if (profilePromise) {
      const profile = await profilePromise
      if (profile?.roles?.includes('pre-opt-in-author') || profile?.roles?.includes('pre-setup-author')) {
        router.replace({ name: 'SetupAccountPage' })
      }
    }
  } catch (err) {
    console.error(err)
  }
})

</script>

<style lang="scss">
@use "assets/scss/variables" as vars;
@forward "assets/scss/variables";
@forward "assets/scss/resets";
@forward "assets/scss/typography";
@forward "assets/scss/lists";
@forward "assets/scss/cards";
@forward "assets/scss/buttons";
@forward "assets/scss/labels";
@forward "assets/scss/utilities";
@forward "assets/scss/helpers";
@forward "assets/scss/loading";
@forward "assets/scss/icons";
@forward "assets/scss/avatars";
@forward "assets/scss/empty-states";

// Global component styles
@forward "assets/scss/tabs";
@forward "assets/scss/forms";

// Global layout styles
@forward "assets/scss/grid";
@forward "assets/scss/layouts/layout-sidebar";
@forward "assets/scss/layouts/layout-search-results";
@forward "assets/scss/layouts/layout-wizard";

body {
  interpolate-size: allow-keywords;
  background-color: vars.$light-blue;
  overflow-x: hidden;
}

.scrollLock {
  overflow: hidden;
}
</style>
