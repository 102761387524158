<template>
  <div>
    <button
      :aria-expanded="String(showList)"
      class="trigger"
      :class="{ 'trigger--closed': !showList, 'trigger--open': showList }"
      @click="showList = !showList"
    >
      <slot name="trigger-text" />
    </button>
    <transition name="slide">
      <section
        v-show="showList"
      >
        <slot name="list" />
      </section>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showList: !this.collapsed
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;
@use "@/assets/scss/animations" as *;

.trigger {
  color: $blue;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding: 0 1rem 0 0;
  outline: transparent;

  &::after {
    content: "";
    position: absolute;
    top: 0.5rem;
    right: 0;
    margin-left: 6px;
    width: 0;
    height: 0;
  }
}

.trigger--closed {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: currentcolor transparent transparent;
  }
}

.trigger--open {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 0 4px 4px;
    border-color: transparent transparent currentcolor;
  }
}

</style>
